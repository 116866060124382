import { chipClasses } from "@mui/material";
import { frFR as coreFrFr } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";

import {
  BACKGROUND_COLOR,
  BORDER_COLOR,
  BORDER_RADIUS,
  COLOR_PALETTE,
  FONT_SIZE,
  FONT_WEIGHT,
  SIZE,
  SPACING,
  TEXT_COLOR,
} from "@constants";
import { Inter } from "@next/font/google";

// needed for the font to be loaded with inherit
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _inter = Inter({ subsets: ["latin"] });

// FIXME: Import was removed from here because of a webpack import resolution error
// It needs to be reimported when things are corrected
// import { hexToRgba } from "@utils";
const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x: string) => parseInt(x, 16));
  return `rgba(${r as string},${g as string},${b as string},${alpha})`;
};

export const palette = {
  primary: {
    main: "#001159",
    900: "#001159",
    800: "#152778",
    700: "#2F4090",
    500: "#6171C4",
    200: "#B9C3FF",
    100: "#DEE1FF",
    50: "#F0EEFF",
  },
  secondary: {
    main: "#BD5A2E",
    900: "#370E00",
    800: "#591D00",
    700: "#7E2C00",
    500: "#BD5A2E",
    300: "#FE8C5B",
    100: "#FFDBCE",
    50: "#FCE9E6",
  },
  text: {
    ...TEXT_COLOR,
    primary: "#001159",
    secondary: "#7F89A3",
    disabled: "#B0B4C2",
  },
  background: {
    ...BACKGROUND_COLOR,
    paper: "#FFFFFF",
    default: "#FFFFFF",
    darker: "#031155",
  },
  border: BORDER_COLOR,
  action: {
    selected: "#F0EEFF",
  },
  success: {
    main: "#398646",
    light: "#EBFFE7",
  },
  warning: {
    main: "#BD5A2E",
    light: "#FDF7BC",
  },
  error: {
    main: "#BA1A1A",
    light: "#FFDAD6",
  },

  grey: {
    800: "#252631",
    700: "#323442",
    600: "#565868",
    500: "#999BAA",
    400: "#B0B4C2",
    300: "#0011590D",
    200: "#EDEDF5",
  },

  // temporary success, warning and error labels, until we re-unify all our colors
  green: {
    main: "#3B8573",
    light: "#4FAE4F",
  },
  orange: {
    main: "#FA6E32",
  },
  red: {
    main: "#AF362E",
  },
  deepPurple: {
    main: COLOR_PALETTE.deepPurple,
    light: COLOR_PALETTE.lilac,
    dark: COLOR_PALETTE.darkBlue,
    contrastText: COLOR_PALETTE.BASE[0],
  },
  color: COLOR_PALETTE,
};

// const buttonVariants = {
//   primary: {
//     color: COLOR_PALETTE.BASE[0],
//     border: `1px solid ${BACKGROUND_COLOR.default}`,
//     backgroundColor: BACKGROUND_COLOR.default,
//     "&:hover": {
//       backgroundColor: COLOR_PALETTE.electricBlue,
//       border: `1px solid ${COLOR_PALETTE.electricBlue}`,
//     },
//   },
//   secondary: {
//     color: TEXT_COLOR.mainInfo,
//     border: `1px solid ${COLOR_PALETTE.BASE[400]}`,
//     backgroundColor: COLOR_PALETTE.BASE[0],
//     "&:hover": {
//       backgroundColor: COLOR_PALETTE.BASE[100],
//       border: `1px solid ${COLOR_PALETTE.BASE[100]}`,
//     },
//   },
//   error: {
//     color: COLOR_PALETTE.BASE[0],
//     backgroundColor: BACKGROUND_COLOR.error,
//     "&:hover": {
//       backgroundColor: BACKGROUND_COLOR.error,
//     },
//   },
//   info: {
//     color: BACKGROUND_COLOR.default,
//     backgroundColor: COLOR_PALETTE.BASE[0],
//     "&:hover": {
//       backgroundColor: COLOR_PALETTE.BASE[300],
//     },
//   },
// };

// Create a theme instance.
const theme = createTheme(
  {
    typography: {
      fontFamily: "inherit",
      fontSize: 16,
      caption2: {
        fontSize: 11,
      },
      subHeadline: {
        fontSize: 15,
      },

      headline: {
        fontSize: 17,
      },
      bodyCopy: {
        fontSize: FONT_SIZE[100],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[400],
        fontStyle: "normal",
      },
      bodyCopySmall: {
        fontSize: FONT_SIZE[85],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[400],
        fontStyle: "normal",
        letterSpacing: "0.0175rem",
      },
      bodyCopyStrong: {
        fontSize: FONT_SIZE[100],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[700],
        fontStyle: "normal",
      },
      heading3XL: {
        fontSize: FONT_SIZE[400],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[900],
        letterSpacing: "-0.012rem",
        fontStyle: "normal",
      },
      heading2XL: {
        fontSize: FONT_SIZE[300],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[700],
        letterSpacing: "-0.009rem",
        fontStyle: "normal",
      },
      headingXL: {
        fontSize: FONT_SIZE[200],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[800],
        letterSpacing: "-0.006rem",
        fontStyle: "normal",
      },
      headingXLAlt: {
        fontSize: FONT_SIZE[200],
        lineHeight: "120%",
        fontWeight: FONT_WEIGHT[800],
        letterSpacing: "0.06rem",
        fontStyle: "normal",
      },
      headingLG: {
        fontSize: FONT_SIZE[125],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[700],
        fontStyle: "normal",
      },
      headingSM: {
        fontSize: FONT_SIZE[75],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[600],
        letterSpacing: "0.0225rem",
        fontStyle: "normal",
        textTransform: "uppercase",
      },
      bodyStrong: {
        fontSize: FONT_SIZE[100],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[700],
        fontStyle: "normal",
      },
      bodyEmphasize: {
        fontSize: FONT_SIZE[100],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[300],
        letterSpacing: "-0.012rem",
      },
      body: {
        fontSize: FONT_SIZE[100],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[400],
        fontStyle: "normal",
      },
      bodySmall: {
        fontSize: FONT_SIZE[85],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[400],
        letterSpacing: "0.0175rem",
        fontStyle: "normal",
      },
      buttonText: {
        fontSize: FONT_SIZE[85],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[700],
        letterSpacing: "0.0175rem",
        fontStyle: "normal",
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
      tags: {
        fontSize: FONT_SIZE[85],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[400],
        letterSpacing: "0.02625rem",
        fontStyle: "normal",
      },
      tagsStrong: {
        fontSize: FONT_SIZE[85],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[700],
        letterSpacing: "0.02625rem",
        fontStyle: "normal",
      },
      legend: {
        fontSize: FONT_SIZE[75],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[500],
        letterSpacing: "0.02625rem",
        fontStyle: "normal",
      },
      button: {
        fontSize: FONT_SIZE[85],
        lineHeight: "150%",
        fontWeight: FONT_WEIGHT[700],
        letterSpacing: "0.0175rem",
        fontStyle: "normal",
        textTransform: "none",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    fontSize: FONT_SIZE,
    fontWeight: FONT_WEIGHT,
    size: SIZE,
    spacings: SPACING,
    radius: BORDER_RADIUS,
    palette,

    // We go with this approach to make the values declared
    // in the theme as the palette usable for the override
    // theme.;
    components: {
      MuiChip: {
        variants: [
          {
            props: { size: "large" },
            style: {
              height: SIZE[40],
              padding: `${SIZE[4]} ${SIZE[8]}`,
              borderRadius: BORDER_RADIUS[4],
              [`& .${chipClasses.label}`]: {
                fontSize: FONT_SIZE[85],
                lineHeight: "150%",
                fontWeight: FONT_WEIGHT[400],
                letterSpacing: "0.02625rem",
                fontStyle: "normal",
              },
            },
          },
        ],
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: palette.background?.default,
            color: "black",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.className?.includes("menu-item-icon") && {
              width: "2rem",
              height: "2rem",
            }),
          }),
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.className?.includes("menu-item-avatar") && {
              width: "2rem",
              height: "2rem",
            }),
          }),
        },
      },
      MuiStep: {
        styleOverrides: {
          vertical: {
            paddingBottom: 8,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            fontSize: 15,
            fontWeight: "bold",
            "&.Mui-active, &.Mui-completed": {
              fontWeight: "bold",
              color: palette.text.primary,
            },
            "&.Mui-disabled": {
              color: palette.text.disabled,
            },
          },
          iconContainer: {
            svg: {
              height: 32,
              width: 32,
              fill: palette.grey[5],
              "&.Mui-active, &.Mui-completed": {
                fill: palette.text.primary,
              },
            },
            text: {
              fontSize: 12,
              fill: palette.text.primary,
              fontWeight: "bold",
            },
            "& .Mui-active text": {
              fill: "white",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: BORDER_RADIUS[2],
            display: "inline-flex",
            padding: `${SIZE[8]} ${SIZE[12]}`,
            justifyContent: "center",
            alignItems: "center",
            gap: SIZE[4],
            height: SIZE[40],

            fontSize: FONT_SIZE[85],
            lineHeight: "150%",
            fontWeight: FONT_WEIGHT[700],
            letterSpacing: "0.0175rem",
            fontStyle: "normal",

            "& .MuiButton-endIcon": {
              marginRight: SIZE[4],
              marginLeft: 0,
            },

            "& .MuiButton-startIcon": {
              marginLeft: SIZE[4],
              marginRight: 0,
            },

            boxShadow: "none",

            textTransform: "initial",
            "&.Mui-disabled": {
              backgroundColor: COLOR_PALETTE.BASE[100],
              "& svg": {
                fill: COLOR_PALETTE.BASE[400],
              },
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          color: "primary",
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: "8px !important",
            lineHeight: "normal",
            "&::placeholder": {
              color: palette.grey[600],
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            display: "flex",
            alignItems: "center",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: palette.primary[800],
            fontWeight: "bold",
            marginBottom: 8,
            whiteSpace: "normal",
          },
          shrink: {
            transform: "none",
            position: "relative",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            whiteSpace: "pre-line",
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            "& .content": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: palette.background.paper,
              padding: "16px 24px",
              paddingTop: "3.5rem",
              borderRadius: "1rem",
              "&:focus-visible": {
                outline: "none",
              },
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: hexToRgba(palette.primary.main, 0.5),
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          "html,body": {
            height: "auto",
            padding: 0,
            margin: 0,
            backgroundColor: palette.background.default,
          },
          body: {
            height: "100%",
          },
          a: {
            color: "#0D0DBA !important",
            textDecoration: "none",
          },
          "*": {
            boxSizing: "border-box",
          },
          "body > div:first-of-type": {
            height: "100%",
          },
        },
      },
    },
  },
  coreFrFr, // core translations
);

export default theme;

import { Feature, Organization, ToggleFeatureParams } from "@typings";

import { organizationsAPI } from "./api";

export interface UpdateOrganizationParams {
  body: {
    phone: string;
  };
}

const update = (params: UpdateOrganizationParams): Promise<void> => organizationsAPI.put("/me", params.body);

const getMy = async (): Promise<Organization> => {
  const { data } = await organizationsAPI.get("/me");
  return data;
};

const enableFeature = (params: ToggleFeatureParams): Promise<void> => {
  return organizationsAPI.post(`/${params.groupID}/features/${params.name}`);
};

const disableFeature = (params: ToggleFeatureParams): Promise<void> => {
  return organizationsAPI.delete(`/${params.groupID}/features/${params.name}`);
};

const listFeatures = async (groupID: string): Promise<Feature[]> => {
  const { data } = await organizationsAPI.get(`/${groupID}/features`);
  return data;
};

export const OrganizationsAPI = {
  getMy,
  update,
  enableFeature,
  disableFeature,
  listFeatures,
};

import { FC, useMemo } from "react";

import { useTranslation } from "next-i18next";

import { Cached as CachedIcon } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";

import { loadTranslations } from "@lib";
import { StatusCategories } from "@typings";
import { ApplicationCategoryToStyle } from "@utils";

import { useFetchApplicationStatuses } from "@hooks/queries";

type Props = {
  status: string;
  statusReason?: string | null;
};

export const StatusChip: FC<Props> = ({ status, statusReason }) => {
  const { t } = useTranslation(["application-status"]);
  loadTranslations("application-status");

  const { data: statuses, isLoading } = useFetchApplicationStatuses();

  const statusToCategory = useMemo<Record<string, string>>(() => {
    if (statuses === undefined) {
      return {};
    }
    return Object.fromEntries(statuses.map((status) => [status.label, StatusCategories[status.category]]));
  }, [statuses]);

  const chipStyle = ApplicationCategoryToStyle[statusToCategory[status]];

  if (isLoading) {
    return (
      <Chip
        label={t(statusReason ? `reasons.${status}.labels.${statusReason}` : status, {
          nsSeparator: ":",
          ns: "application-status",
        })}
      />
    );
  }

  return (
    <Chip
      label={t(statusReason ? `reasons.${status}.labels.${statusReason}` : status, {
        nsSeparator: ":",
        ns: "application-status",
      })}
      sx={(theme) => ({ ...chipStyle.sx, borderRadius: theme.radius[1] })}
      icon={chipStyle.icon}
    />
  );
};

export const StatusChipIcon: FC<Props> = ({ status }) => {
  const { data: statuses, isLoading } = useFetchApplicationStatuses();

  const statusToCategory = useMemo<Record<string, string>>(() => {
    if (statuses === undefined) {
      return {};
    }
    return Object.fromEntries(statuses.map((status) => [status.label, StatusCategories[status.category]]));
  }, [statuses]);

  const chipStyle = ApplicationCategoryToStyle[statusToCategory[status]];

  if (isLoading) {
    return <CachedIcon />;
  }

  return (
    <Box
      sx={(theme) => ({
        ...chipStyle.sx,
        borderRadius: theme.radius[1],
        paddingX: theme.spacings[8],
        paddingY: theme.spacings[4],
      })}
    >
      {chipStyle.icon}
    </Box>
  );
};

import { useMemo } from "react";

import { AxiosError } from "axios";

import { ApplicationApi, JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Application, ApplicationWithLocation } from "@typings";

export const useApplications = (campaignID: string) => {
  return useQuery<Application[], AxiosError, ApplicationWithLocation[]>({
    queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS, campaignID],
    queryFn: () => ApplicationApi.list({ campaign_id: campaignID }),
  });
};

export const useApplicationsWithJobInfo = (campaignID: string) => {
  const applications = useApplications(campaignID);

  const jobs = useQueries({
    queries:
      applications.data?.map((application) => ({
        queryKey: [QUERY_KEYS.JOBS, application.job_id],
        queryFn: () => JobsApi.get(application.job_id),
      })) ?? [],
  });

  const applicationsWithJobs = useMemo(
    () =>
      applications.data?.map((application) => {
        const jobData = jobs.find((job) => job.data?.id === application.job_id)?.data;

        if (jobData) {
          application.job_title = jobData.title;
          application.city = jobData.city;
          application.country_code = jobData.country_code;
          application.state = jobData.location_state;
          application.postal_code = jobData.postal_code;
          application.region = jobData.region;
        }

        return application;
      }),
    [applications.data, jobs],
  );

  return {
    ...applications,
    data: applicationsWithJobs,
  };
};

export const useApplication = (applicationID: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS, applicationID],
    queryFn: () => ApplicationApi.get(applicationID),
    // refetch the application every minute to ensure that the messages history is
    // up-to-date
    refetchInterval: 60 * 1000,
  });
};

export const useApplicationWithJobInfo = (applicationID: string) => {
  const application = useApplication(applicationID);

  const job = useQuery({
    queryKey: [QUERY_KEYS.JOBS, application.data?.job_id],
    queryFn: () => JobsApi.get(application.data?.job_id ?? ""),
    enabled: !!application.data,
  });

  const applicationWithJob = useMemo(() => {
    if (!application.data || !job.data) {
      return application.data;
    }

    application.data.job_title = job.data.title;
    application.data.city = job.data.city;
    application.data.country_code = job.data.country_code;
    application.data.state = job.data.location_state;
    application.data.postal_code = job.data.postal_code;
    application.data.region = job.data.region;

    return application.data;
  }, [application.data, job.data]);

  return {
    ...application,
    data: applicationWithJob,
  };
};

// Those are the constant query keys that will be used by react-query
// to cache the data so we can later invalidate the cache if needed.
// The query must be unique to avoid invalidating the wrong data.
export const QUERY_KEYS = {
  APPLICATIONS: "applications",
  APPLICATIONS_CANDIDATES: "applications.candidates",
  ATS_INTEGRATIONS: "ats_integrations",
  APPLICATION_REPORTS: "application_reports",
  APPLICATIONS_STATUSES: "applications.statuses",
  APPLICATIONS_STATUSES_BY_CATEGORY: "applications.statuses_by_category",
  APPLICATION_SCORE: "application_score",
  APPLICATION_ALL_SCORES: "application_all_scores",
  APPLICATIONS_USERS: "applications.users",
  APPLICATION_STATUS_REASON: "application_status_reason",
  APPLICATION_DOCUMENTS: "application_documents",
  APPLICATION_DOCUMENTS_LOGS: "application_documents_logs",
  APPLICATION_SHARES: "application_shares",

  CAMPAIGN_APPLICATIONS: "campaign_applications",
  CAMPAIGN_APPLICATIONS_QUESTIONS: "campaign_applications.questions",
  CAMPAIGN_HAS_SCORING: "has_scoring",

  INTERVIEW: "interview",
  INTERVIEW_CONFIGURATION: "interview_configuration",

  EXPORTERS: "exporters",
  EXPORT_HISTORIES: "export_histories",

  JOBS: "jobs",

  MESSAGING_REPORTS: "messaging_reports",
  MESSAGING_HISTORIES: "messaging.histories",
  MESSAGING_REMINDERS: "messaging.reminders",
  MESSAGING_RECRUITER_REMINDERS: "messaging.recruiter_reminders",
  MESSAGING_TRIGGERS: "messaging.triggers",

  ORGANIZATIONS: "organizations",
  ORGANIZATIONS_REQUEST_FORM_LINK: "organizations.request-form-link",
  ORGANIZATIONS_FEATURES: "organizations.features",

  SOURCING_CAMPAIGNS: "sourcing_campaigns",

  USERS: "users",
  CANDIDATES: "candidates",

  CLICK_TO_CALL_TOKEN: "click_to_call.token",
  CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS: "click_to_call.number_verification_status",
  CLICK_TO_CALL_APPLICATION_HISTORIES: "click_to_call.application_histories",
};

import { FC, useCallback, useEffect, useMemo, useState } from "react";

import moment, { Moment } from "moment";
import { TFunction, Trans, useTranslation } from "next-i18next";
import { Controller, useForm } from "react-hook-form";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { toast } from "react-toastify";
import * as yup from "yup";

import { AccessAlarm as AccessAlarmIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers-pro";

import { InfoBox, Modal } from "@work4Labs/design-system";

import { ApplicationApi, InterviewApi, InterviewCreation } from "@api";
import { Form, FormDatePicker, FormInput, updateApplicationStatus } from "@components";
import { COLOR_PALETTE, QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { InterviewsConfiguration } from "@typings";
import { Logger } from "@utils";

import { yupResolver } from "@hookform/resolvers/yup";

import { ConfirmApplicationInterview } from "./confirm-creation";

// An enum to represent the two steps we have in this Dialog.
// We first let the user fill the form for the interview and then review
// his information. We want to be able to switch from one view to the other
// without having to re-render the modal, so we change it's content and we rely
// on this enum to know what part to show (and a state).
export enum InterviewFormCreationStep {
  FORM_FILL = "FORM_FILL",
  VALIDATION = "VALIDATION",
}

const getValidationSchema = (t: TFunction) => {
  return yup.object().shape({
    date: yup
      .date()
      .min(moment().subtract(1, "days"), t("min_date"))
      // TypeError is required because it's possible that the value has been set to null before.
      .typeError(t("date_required"))
      .required(t("date_required")),
    time_start: yup
      .date()
      // TypeError is required because it's possible that the value has been set to null before.
      .typeError(t("time_start_required"))
      .required(t("time_start_required")),
    time_end: yup
      .date()
      // TypeError is required because it's possible that the value has been set to null before.
      .typeError(t("time_end_required"))
      .required(t("time_end_required"))
      .when(["time_start"], (time_start, schema) => {
        return time_start !== "" && time_start != "Invalid Date" ? schema.min(time_start, t("time_end_min")) : schema;
      }),
    location: yup.string().required(t("location_required")),
    stakeholders: yup.array().of(yup.string().email()),
    additional_information: yup.string(),
  });
};

export interface InterviewFormCreation {
  application_id: string;
  campaign_id: string;

  date: Moment | null;
  time_start: Moment | null;
  time_end: Moment | null;

  location: string;
  stakeholders: string[];
  additional_information: string;
}

type ApplicationInterviewModalProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onSkip?: () => void;

  campaignId: string;

  applicationIDs: string[];
  interviewConfiguration: InterviewsConfiguration;

  allowSkip?: boolean;
};

const modalLabel = (email: string, index: number, removeEmail: (index: number) => void) => (
  <div data-tag={true} key={index}>
    {email}
    <button data-span={true} data-tag-handle={true} onClick={() => removeEmail(index)}>
      ×
    </button>
  </div>
);

export const ApplicationInterviewModal: FC<ApplicationInterviewModalProps> = ({
  open,
  onConfirm,
  onCancel,
  onSkip,

  campaignId,
  applicationIDs,
  interviewConfiguration,

  allowSkip = false,
}) => {
  const { t } = useTranslation(["create-interview-modal"]);
  loadTranslations("create-interview-modal");

  const theme = useTheme();

  const queryClient = useQueryClient();

  const [advancedOptionOpen, setAdvancedOptionOpen] = useState(false);
  const [interviewCreationStep, setInterviewCreationStep] = useState<InterviewFormCreationStep>(
    InterviewFormCreationStep.FORM_FILL,
  );

  const [formValuesForValidationModal, setFormValuesForValidationModal] = useState<InterviewCreation>();

  // create a state for the ReactMultipleEmail component instead of using the form state because else it's buggy.
  const [stakeholderEmails, setStakeholderEmails] = useState<string[]>([]);

  const [applicationsWithInterview, setApplicationsWithInterview] = useState<Record<string, boolean>>({});

  useEffect(() => {
    // search of applications with an interview
    applicationIDs.map(async (applicationID) => {
      const existingInterviewData = await queryClient.fetchQuery({
        queryKey: [QUERY_KEYS.INTERVIEW, applicationID],
        queryFn: () => InterviewApi.getInterview(applicationID),
      });

      const hasInterviewAlready =
        existingInterviewData?.find((interview) => !interview.deleted && interview.getInterviewEndDate() > moment()) !==
        undefined;

      if (hasInterviewAlready) {
        setApplicationsWithInterview((prev) => ({ ...prev, [applicationID]: true }));
      }
    });
  }, [applicationIDs, queryClient]);

  const interviewMutation = useMutation({
    mutationFn: InterviewApi.createInterview,
    onSuccess: (interview) => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.INTERVIEW, interview.application_id],
        })
        .catch(() => {});
    },
    onError: () => {
      toast.error(t("interview_fail_to_create"));
    },
  });

  const statusesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(),
    refetchOnWindowFocus: true,
    gcTime: Infinity,
  });

  const INITIAL_VALUES = useMemo(
    () => ({
      application_id: "",
      campaign_id: "",

      date: null,
      time_start: null,
      time_end: null,

      stakeholders: [],

      location: interviewConfiguration.location,
      additional_information: interviewConfiguration.additional_information,
    }),
    [interviewConfiguration],
  );

  const validationSchema = getValidationSchema(t);
  const interviewForm = useForm<InterviewFormCreation>({
    shouldUnregister: false,
    defaultValues: INITIAL_VALUES,
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const {
    reset,
    control,
    getValues,
    setValue,
    setError,
    trigger,
    clearErrors,
    formState: { errors },
  } = interviewForm;

  // On form submit, we create a list of promises to update the statuses and create the comments.
  // We then run all queries in // and show a toast when we're done.
  const onFormSubmit = useCallback(
    async (data: InterviewCreation) => {
      await Promise.all(
        applicationIDs.map(async (applicationID) => {
          const statusInterview = statusesQuery.data?.filter((status) => status.label === "interview") ?? [];

          if (statusInterview.length === 0) throw new Error("Did not find status interview for interview creation");

          await updateApplicationStatus({
            applicationID: applicationID,
            campaignID: campaignId,
            queryClient: queryClient,
            newStatus: statusInterview[0],
            updateParams: { sendSMS: true },
          });

          if (applicationsWithInterview[applicationID] !== true) {
            interviewMutation.mutate({
              application_id: applicationID,
              campaign_id: campaignId,

              date: moment(data.date),
              time_start: moment(data.time_start),
              time_end: moment(data.time_end),

              location: data.location,
              stakeholders: data.stakeholders,
              additional_information: data.additional_information,
            });
          }
        }),
      );

      toast.success(t("interview_created"));
      onConfirm();
      setApplicationsWithInterview({});
    },
    [
      applicationIDs,
      interviewMutation,
      queryClient,
      campaignId,
      statusesQuery.data,
      onConfirm,
      t,
      applicationsWithInterview,
    ],
  );

  const onValidationStep = useMemo(() => {
    return interviewCreationStep === InterviewFormCreationStep.VALIDATION && formValuesForValidationModal !== undefined;
  }, [interviewCreationStep, formValuesForValidationModal]);

  const onConfirmButton = useCallback(() => {
    if (onValidationStep && formValuesForValidationModal !== undefined) {
      onFormSubmit(formValuesForValidationModal).catch(Logger.error);
    } else {
      const date = getValues()["date"];
      const time_start = moment(getValues()["time_start"]);

      // make sure that the aggregation of the date and the time_start is not in the past.
      if (date && time_start) {
        const definedDate = moment(date);
        definedDate.set("hours", time_start.hours());
        definedDate.set("minutes", time_start.minutes());

        if (definedDate < moment()) {
          setError("time_start", { type: "manual", message: t("min_time_start") });
          return;
        }
      }

      trigger().then((isValid) => {
        if (isValid) {
          setInterviewCreationStep(InterviewFormCreationStep.VALIDATION);
          setFormValuesForValidationModal(getValues() as InterviewCreation);
        }
      });
    }
  }, [
    onValidationStep,
    formValuesForValidationModal,
    onFormSubmit,
    getValues,
    trigger,
    setInterviewCreationStep,
    setFormValuesForValidationModal,
    setError,
    t,
  ]);

  const _onCancel = useCallback(() => {
    if (onValidationStep) {
      setInterviewCreationStep(InterviewFormCreationStep.FORM_FILL);
    } else {
      reset(INITIAL_VALUES);
      onCancel();
      clearErrors();
    }
  }, [onValidationStep, setInterviewCreationStep, reset, onCancel, clearErrors, INITIAL_VALUES]);

  const modalCustomButtons = useCallback(
    (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
      const buttonStyle = {
        fontSize: "1rem",
        fontWeight: "600",
        textTransform: "none",
        borderRadius: "8px",
      };
      return (
        <>
          <Button
            variant="outlined"
            onClick={() => {
              onClose?.();
            }}
            sx={{
              ...buttonStyle,
              color: COLOR_PALETTE.BASE[800],
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "#F2F3F7",
              },
              border: `1px solid ${COLOR_PALETTE.BASE[800]}`,
            }}
          >
            {t("cancel")}
          </Button>
          {allowSkip && !onValidationStep && (
            <Button
              onClick={onSkip}
              sx={{
                ...buttonStyle,
                color: COLOR_PALETTE.BASE[800],
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "#F2F3F7",
                },
                border: `1px solid ${COLOR_PALETTE.BASE[800]}`,
              }}
            >
              {t("skip")}
            </Button>
          )}
          <Button
            onClick={() => {
              onConfirm?.();
            }}
            sx={{
              ...buttonStyle,
              marginRight: "0.5rem",
              color: "white",
              backgroundColor: COLOR_PALETTE.deepPurple,
              "&:hover": {
                backgroundColor: COLOR_PALETTE.deepPurple,
              },
            }}
          >
            {t("submit_interview_creation")}
          </Button>
        </>
      );
    },
    [onSkip, t, onValidationStep, allowSkip],
  );

  return (
    <Modal
      isOpen={open}
      aria-label="application-interview-modal-creation"
      aria-describedby="application-interview-modal-creation"
      scroll="body"
      modalTitle={applicationIDs.length > 1 ? t("bulk_modal_title") : t("modal_title")}
      title={onValidationStep ? t("title_confirmation") : t("title")}
      modalIcon={<AccessAlarmIcon />}
      customActions={modalCustomButtons}
      onConfirm={onConfirmButton}
      onClose={_onCancel}
      options={{
        maxWidth: "750px",
      }}
    >
      <ThemeProvider theme={theme}>
        <Form
          methods={interviewForm}
          submitHandler={(data) => {
            onFormSubmit(data as InterviewCreation).catch(Logger.error);
          }}
        >
          {onValidationStep && formValuesForValidationModal !== undefined ? (
            <ConfirmApplicationInterview
              formValues={formValuesForValidationModal}
              nbApplicationWithInterview={Object.keys(applicationsWithInterview).length}
            />
          ) : (
            <>
              <Grid container sx={{ paddingTop: "1rem" }}>
                {applicationIDs.length > 1 && (
                  <div style={{ marginBottom: "1rem" }}>
                    <InfoBox title={""} level={"warning"}>
                      <Trans
                        t={t}
                        i18nKey="bulk_warning_box"
                        components={[<strong />]}
                        values={{ count: applicationIDs.length }}
                      />
                    </InfoBox>
                  </div>
                )}
                <InfoBox title={""} level={"info"}>
                  <Trans
                    t={t}
                    i18nKey="info_box"
                    // eslint-disable-next-line react/jsx-key
                    components={[<strong key={0} />]}
                  />
                </InfoBox>
              </Grid>
              <Grid container sx={{ paddingTop: "1rem" }}>
                <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
                  <FormDatePicker
                    fullWidth
                    name="date"
                    required
                    minDate={moment()}
                    label={
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <span>{t("date_label")}</span>
                      </Box>
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth error={!!errors.time_start || !!errors.time_end}>
                    <InputLabel shrink htmlFor={"time_start"} sx={{ display: "list-item" }}>
                      {t("times_label")}
                    </InputLabel>
                  </FormControl>

                  <Grid container>
                    <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
                      <Controller
                        name={"time_start"}
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth error={!!errors.time_start}>
                            <TimePicker
                              {...field}
                              onChange={(value: Moment) => {
                                field.onChange(value);

                                if (value && interviewConfiguration.duration) {
                                  // update the time_end value based on the default configuration.
                                  // we create a new moment value because `.add` update the variable in place.
                                  setValue("time_end", moment(value).add(interviewConfiguration.duration, "minutes"));
                                  clearErrors("time_end");
                                }
                              }}
                              slotProps={{ textField: { InputProps: { "aria-label": "time-start-interview" } } }}
                              ampm={moment.locale().includes("en")}
                            />

                            {errors.time_start && <FormHelperText>{errors.time_start?.message}</FormHelperText>}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ paddingRight: "1rem" }}>
                      <Controller
                        name={"time_end"}
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth error={!!errors.time_end}>
                            <TimePicker
                              {...field}
                              onChange={(value: Moment) => {
                                field.onChange(value);
                              }}
                              slotProps={{ textField: { InputProps: { "aria-label": "time-end-interview" } } }}
                              ampm={moment.locale().includes("en")}
                            />

                            {errors.time_end && <FormHelperText>{errors.time_end?.message}</FormHelperText>}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container sx={{ paddingTop: "1rem" }}>
                <FormInput
                  name="location"
                  label={t("location_label")}
                  required
                  fullWidth
                  multiline
                  slotProps={{ input: { "aria-label": "location-interview" } }}
                />
              </Grid>

              <Grid container>
                <Box sx={{ flex: 1 }}>
                  <Link
                    sx={(theme) => ({
                      float: "right",
                      color: `${theme.palette.grey[600]} !important`,
                      padding: "1rem 0px",
                    })}
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setAdvancedOptionOpen((old) => {
                        return !old;
                      });
                    }}
                  >
                    {t(advancedOptionOpen ? "hide_advanced_options" : "open_advanced_options")}
                  </Link>
                </Box>
              </Grid>

              <Grid container sx={{ width: "100%" }}>
                <Grid item xs={12} sx={{ width: "100%" }}>
                  <Collapse in={advancedOptionOpen} sx={{ width: "100%" }}>
                    <Grid container sx={{ paddingTop: "1rem" }}>
                      <InputLabel shrink htmlFor={"stakeholders"} sx={{ display: "list-item" }}>
                        {t("stakeholders_label")}
                        <FormHelperText>{t("stakeholders_helper_text")}</FormHelperText>
                      </InputLabel>
                      <ReactMultiEmail
                        style={{
                          height: "81px",
                        }}
                        placeholder={t("stakeholders_placeholder")}
                        emails={stakeholderEmails}
                        onChange={(emails: string[]) => {
                          setStakeholderEmails(emails);
                          setValue("stakeholders", emails);
                        }}
                        getLabel={modalLabel}
                      />
                    </Grid>
                    <Grid container sx={{ paddingTop: "2rem" }}>
                      <FormInput
                        name="additional_information"
                        label={t("additional_information_label")}
                        placeholder={t("additional_information_placeholder")}
                        required
                        multiline
                        fullWidth
                        minRows={5}
                      />
                    </Grid>
                  </Collapse>
                </Grid>
              </Grid>
            </>
          )}
        </Form>
      </ThemeProvider>
    </Modal>
  );
};

import React, { FC } from "react";

import { MRT_TableInstance } from "material-react-table";

import { ApplicationListItem } from "@typings";

import { MultiselectFilter } from "./multiselect-filter";

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
  columns: string[];
  columnsVisibility: Record<string, boolean>;
};

export const MultiselectFilters: FC<Props> = ({ table, columns, columnsVisibility }) => {
  return (
    <>
      {columns.map((columnID) =>
        table.getColumn(columnID) && columnsVisibility[columnID] ? (
          <MultiselectFilter key={columnID} table={table} columnID={columnID} />
        ) : (
          <></>
        ),
      )}
    </>
  );
};

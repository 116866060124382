import { FC, useEffect, useState } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { TextField } from "@mui/material";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
};
export const GlobalFilter: FC<Props> = ({ table }) => {
  const { t } = useTranslation(["common"]);
  loadTranslations("common");

  const [filterValue, setFilterValue] = useState("");

  const { setGlobalFilter } = table;

  useEffect(() => {
    const timer = setTimeout(() => {
      setGlobalFilter(filterValue);
    }, 500);
    return () => clearTimeout(timer);
  }, [filterValue, setGlobalFilter]);

  return (
    <TextField
      id="global_filter"
      placeholder={t("search")}
      value={filterValue}
      onChange={(e) => {
        setFilterValue(e.target.value);
      }}
      sx={(theme) => ({
        backgroundColor: theme.palette.color.BASE[100],
      })}
      inputProps={{
        style: {
          padding: "0.5rem 1rem",
        },
      }}
    />
  );
};

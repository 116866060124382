import { useRouter } from "next/router";

import { FC, useCallback, useEffect, useRef, useState } from "react";

import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_Header,
  MRT_Row,
  MRT_TableInstance,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import moment from "moment";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { TFunction } from "react-i18next";
import { useApplicationsStore } from "stores";

import { Box, Tooltip, tablePaginationClasses } from "@mui/material";

import { ApplicationApi, ScoringAPI } from "@api";
import {
  ApplicationScoringChip,
  FilterValue,
  LastInteractionDateFilter,
  LastInteractionDateFilterFn,
  PageTitle,
  StatusChip,
} from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { MRT_Localization_EN, MRT_Localization_FR } from "@locales";
import { useQuery } from "@tanstack/react-query";
import { ColumnFilter, Row, RowSelectionState } from "@tanstack/react-table";
import { ApplicationListItem, SourcingCampaign } from "@typings";
import { elaspedTimeFromNow, parsePhoneNumber } from "@utils";

import { useScoringRowParams, useUserGroup, useUserPermissions } from "@hooks";
import { useFetchCampaignApplicationsList } from "@hooks/queries";

import { AdvancedFiltersAction } from "./advanced-filters-action";
import { ApplicationsStatusFilter } from "./applications-status-filter";
import { FiltersTags } from "./filters-tags";
import { GlobalFilter } from "./global-filter";
import { GroupedActions } from "./grouped-actions";
import { MultiselectFilters } from "./multiselect-filters";
import { RowActions } from "./row-actions";

const RedDot = () => (
  <Box
    component="span"
    sx={(theme) => ({
      backgroundColor: theme.palette.color.ALERT_RED[400],
      borderRadius: "50%",
      display: "inline-block",
      margin: "0px -1px",
      padding: "4px",
      position: "relative",
      right: "15px",
    })}
  />
);

type Props = {
  sourcingCampaign: SourcingCampaign;
};

const getApplicationName = ({ row }: { row: ApplicationListItem }) => {
  return (row.candidate && `${row.candidate.first_name} ${row.candidate.last_name}`) || "";
};

const nameCell = (
  { row }: { row: MRT_Row<ApplicationListItem> },
  getApplicationName: ({ row }: { row: ApplicationListItem }) => string,
  t: TFunction,
) => {
  const shouldDisplayNotOpened =
    !row.original.last_opened_at || moment(row.original.last_opened_at) < moment(row.original.last_interaction_date);

  if (shouldDisplayNotOpened) {
    return (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: (theme) => ({
              color: "white",
              bgcolor: theme.palette.background.darker,
              textAlign: "center",
            }),
          },
        }}
        title={t("red_dot_tooltip", { ns: "leads" })}
        placement="top"
        arrow
      >
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <RedDot />
          <span>{getApplicationName({ row: row.original })}</span>
        </div>
      </Tooltip>
    );
  }

  return <span>{getApplicationName({ row: row.original })}</span>;
};

const compatibilityCell = ({ row }: { row: MRT_Row<ApplicationListItem> }, id: string) => (
  <ApplicationScoringChip
    renderChip={false}
    applicationID={row.original.id}
    renderChipForLowPercentages={false}
    campaignID={id}
  />
);

const statusCell = ({ cell, row }) => (
  <StatusChip status={String(cell.getValue())} statusReason={row.original.status_reason} />
);

const phoneCell = ({ row }: { row: MRT_Row<ApplicationListItem> }) =>
  row.original.candidate?.phone ? parsePhoneNumber(row.original.candidate.phone) : "";

const lastInteractionDateCell = ({ row }: { row: MRT_Row<ApplicationListItem> }, t: TFunction) => {
  return elaspedTimeFromNow(t, moment(row.original.last_interaction_date));
};

interface LastInteractionDateFilterFNProps {
  header: MRT_Header<ApplicationListItem>;
  column: MRT_Column<ApplicationListItem, unknown>;
  table: MRT_TableInstance<ApplicationListItem>;
}

const lastInteractionDateFilterFN = ({ header, column, table }: LastInteractionDateFilterFNProps) => {
  return (
    <LastInteractionDateFilter
      table={table}
      header={header}
      value={column.getFilterValue() as FilterValue}
      onChange={column.setFilterValue}
    />
  );
};

export const Leads: FC<Props> = ({ sourcingCampaign }) => {
  const { t } = useTranslation([
    "application-scoring",
    "application-status",
    "campaign-detail",
    "datagrid",
    "leads",
    "dates",
    "localization",
  ]);
  loadTranslations("application-scoring");
  loadTranslations("application-status");
  loadTranslations("campaign-detail");
  loadTranslations("datagrid");
  loadTranslations("leads");
  loadTranslations("dates");
  loadTranslations("localization");

  const isFirstRender = useRef(true);

  const router = useRouter();
  const { id } = router.query;

  const setCurrentApplicationID = useApplicationsStore((state) => state.setCurrentApplicationID);
  const setApplicationsID = useApplicationsStore((state) => state.setApplicationsID);
  const setCurrentCampaignID = useApplicationsStore((state) => state.setCurrentCampaignID);

  const { data: session } = useSession();
  const userGroup = useUserGroup();
  const userPermissions = useUserPermissions(session?.accessToken);

  const { applications, isLoading } = useFetchCampaignApplicationsList({
    campaignId: id as string,
    organizationName: userGroup,
  });

  const scoringEnabledQuery = useQuery({
    queryKey: [QUERY_KEYS.CAMPAIGN_HAS_SCORING, sourcingCampaign.campaign_id],
    queryFn: () => ScoringAPI.hasEngine(sourcingCampaign.campaign_id),
  });

  const questionsQuery = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS_QUESTIONS, id],
    queryFn: () =>
      ApplicationApi.listQuestions({
        campaign_id: id as string,
        ...(session?.user.groups ? { organization_names: [session.user.groups[0]] } : {}),
      }),
  });

  const [columns, setColumns] = useState<MRT_ColumnDef<ApplicationListItem>[]>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [filtreableColumns, setFiltreableColumns] = useState<string[]>(["job_title", "job_location"]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  // initial column visibility
  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>({
    name: true,
    email: false,
    status: true,
    phone: true,
    job_title: true,
    job_location: true,
    last_interaction_date: true,
  });

  useEffect(() => {
    const columnsFiltersQuery = (router.query.columnFilters as string) || "[]";
    const globalFilterQuery = router.query.globalFilter as string;

    const columnFilters = localStorage.getItem(`seiza_columnFilters_leads_${id as string}`) || "[]";
    const columnsVisibility = localStorage.getItem(`seiza_columnsVisibility_leads_${id as string}`);

    if (!isFirstRender.current) return;

    const parsedColumnsFiltersQuery = (JSON.parse(columnsFiltersQuery) as ColumnFilter[]) || [];
    const parsedColumnsFilters = (JSON.parse(columnFilters) as ColumnFilter[]) || [];

    const filtersNotInQuery = parsedColumnsFilters.filter(
      (filter) => !parsedColumnsFiltersQuery.some((parsedFilter) => parsedFilter.id === filter.id),
    );

    const mergedFilters = [...parsedColumnsFiltersQuery, ...filtersNotInQuery];

    const filtersWithoutStatus = mergedFilters.filter((filter) => filter.id !== "status");

    setColumnFilters(mergedFilters);
    localStorage.setItem(`seiza_columnFilters_leads_${id as string}`, JSON.stringify(filtersWithoutStatus));
    router.query.columnFilters = columnFilters;

    if (globalFilterQuery) {
      setGlobalFilter(globalFilterQuery);
    }

    if (columnsVisibility) {
      setColumnVisibility((JSON.parse(columnsVisibility) as Record<string, boolean>) || {});
    }

    router.push(router, undefined, { shallow: true });

    isFirstRender.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirstRender.current) return;
    const filtersWithoutStatus = columnFilters.filter((filter) => filter.id !== "status");
    localStorage.setItem(`seiza_columnFilters_leads_${id as string}`, JSON.stringify(filtersWithoutStatus));
    const filtersWithoutQuestions = columnFilters.filter((filter) => !filter.id.startsWith("question_"));
    router.query.columnFilters = JSON.stringify(filtersWithoutQuestions);
    router.push(router, undefined, { shallow: true });
    // reset the row selection on filters change
    setRowSelection({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters]);

  useEffect(() => {
    if (isFirstRender.current) return;
    // reset the row selection on global filter change
    setRowSelection({});
  }, [globalFilter]);

  useEffect(() => {
    if (isFirstRender.current) return;
    localStorage.setItem(`seiza_columnsVisibility_leads_${id as string}`, JSON.stringify(columnVisibility));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnVisibility]);

  // Reset the current application when the campaign is changed.
  useEffect(() => {
    setCurrentApplicationID(null);
    setApplicationsID([]);
  }, [setApplicationsID, setCurrentApplicationID]);

  const { getRowParams: getRowScoreParams } = useScoringRowParams(id as string);

  // construct the table columns
  useEffect(() => {
    const cols: MRT_ColumnDef<ApplicationListItem>[] = [
      {
        id: "name",
        accessorFn: (row: ApplicationListItem) => getApplicationName({ row }),
        Cell: (row) => nameCell(row, getApplicationName, t),
        header: t("name", { ns: "leads" }),
        columnFilterModeOptions: ["contains"],
        minSize: 130,
        enableGlobalFilter: true,
      },
      {
        id: "email",
        accessorFn: (row: ApplicationListItem) => row.candidate?.email || "",
        header: t("email", { ns: "leads" }),
        columnFilterModeOptions: ["contains"],
        minSize: 130,
        enableGlobalFilter: true,
      },
      {
        id: "status",
        accessorFn: (row: ApplicationListItem) => row.status,
        Cell: statusCell,
        header: t(`status`, { ns: "leads" }),
        filterFn: "equals",
        enableGlobalFilter: false,
        enableColumnFilter: false,
        minSize: 100,
      },
      {
        id: "phone",
        accessorFn: (row: ApplicationListItem) => (row.candidate ? row.candidate.phone : ""),
        Cell: phoneCell,
        header: t(`phone`, { ns: "leads" }),
        columnFilterModeOptions: ["fuzzy"],
        minSize: 100,
        enableGlobalFilter: true,
      },
      {
        id: "job_title",
        accessorFn: (row: ApplicationListItem) => row.job?.title,
        header: t(`job_title`, { ns: "leads" }),
        filterFn: "arrayIncludes",
        enableColumnFilter: false,
      },
      {
        id: "created_at",
        accessorFn: (row: ApplicationListItem) => row.created_at,
        Cell: ({ row }) => moment(row.original.created_at).format("L - LT"),
        header: t(`created_at`, { ns: "leads" }),
        enableColumnFilter: false,
        filterVariant: "datetime-range",
        minSize: 200,
      },
      {
        id: "job_location",
        accessorFn: (row: ApplicationListItem) => row.job?.city,
        header: t(`job_location`, { ns: "leads" }),
        filterFn: "arrayIncludes",
        enableColumnFilter: false,
      },
      {
        id: "last_interaction_date",
        accessorFn: (row: ApplicationListItem) => {
          return row.last_interaction_date && new Date(row.last_interaction_date);
        },
        Cell: (row) => lastInteractionDateCell(row, t),
        header: t(`last_interaction_date`, { ns: "leads" }),
        enableGlobalFilter: false,
        Filter: lastInteractionDateFilterFN,
        filterFn: LastInteractionDateFilterFn,
        minSize: 130,
      },
    ];

    if (scoringEnabledQuery.data) {
      cols.splice(2, 0, {
        id: "compatibility",
        accessorFn: (row: ApplicationListItem) => getRowScoreParams(row.id).label,
        filterFn: "arrayIncludes",
        sortingFn: (a, b) => {
          const aRank = getRowScoreParams(a.original.id).rank;
          const bRank = getRowScoreParams(b.original.id).rank;

          return aRank - bRank;
        },
        Cell: (row) => compatibilityCell(row, id as string),
        header: t("compatibility", { ns: "leads" }),
        enableColumnFilter: true,
        enableGlobalFilter: false,
        minSize: 100,
      });
      setFiltreableColumns((prevColumns) => [...new Set([...prevColumns, "compatibility"])]);
      setColumnVisibility((prevColumns) => ({
        ...prevColumns,
        compatibility: prevColumns["compatibility"] !== undefined ? prevColumns["compatibility"] : true,
      }));
    }

    if (questionsQuery.data) {
      const questionsColumns: MRT_ColumnDef<ApplicationListItem>[] = [];
      const questionIDs: string[] = [];
      questionsQuery.data?.forEach((question) => {
        questionsColumns.push({
          id: `question_${question.label}`,
          accessorFn: (row: ApplicationListItem) =>
            row?.answers?.find((answer) => answer.question_label == question.label)?.answer || "",
          header: question.label,
          minSize: 200,
          filterFn: "arrayIncludes",
          enableGlobalFilter: false,
        });
        questionIDs.push(`question_${question.label}`);
      });
      cols.push(...questionsColumns);
      setFiltreableColumns((prevColumns) => [...new Set([...prevColumns, ...questionIDs])]);
      setColumnVisibility((prevColumnVisibility) => ({
        ...prevColumnVisibility,
        ...questionIDs.reduce(
          (acc, question) => ({
            ...acc,
            [question]: prevColumnVisibility[question] !== undefined ? prevColumnVisibility[question] : false,
          }),
          {},
        ),
      }));
    }

    setColumns(cols);
  }, [t, id, questionsQuery.data, scoringEnabledQuery.data, getRowScoreParams]);

  const onRowClick = useCallback(
    (table: MRT_TableInstance<ApplicationListItem>, applicationID: string) => {
      const applicationsID = table.getRowModel().rows.map((row) => row.original.id);
      setCurrentApplicationID(applicationID);
      setApplicationsID(applicationsID);
      setCurrentCampaignID(sourcingCampaign.campaign_id);
      router.push({ pathname: `${router.pathname}/view`, query: { id: sourcingCampaign.campaign_id } }, undefined, {
        shallow: false,
      });
    },
    [router, setApplicationsID, setCurrentApplicationID, setCurrentCampaignID, sourcingCampaign.campaign_id],
  );

  const table = useMaterialReactTable<ApplicationListItem>({
    localization: session?.user?.locale === "en" ? MRT_Localization_EN : MRT_Localization_FR,
    columns,
    data: applications,
    state: {
      isLoading: isLoading,
      globalFilter,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: 50,
        pageIndex: 0,
      },
      columnPinning: {
        right: ["mrt-row-actions"],
      },
    },
    onColumnFiltersChange: (newColumnFilters) => {
      if (isFirstRender.current) return;
      setColumnFilters(newColumnFilters);
    },
    onGlobalFilterChange: (newGlobalFilter: string) => {
      if (isFirstRender.current) return;
      setGlobalFilter(newGlobalFilter);
    },
    onColumnVisibilityChange: (newColumnVisibility) => {
      if (isFirstRender.current) return;
      setColumnVisibility((prevColumnVisibility) => ({ ...prevColumnVisibility, ...newColumnVisibility }));
    },
    onRowSelectionChange: (newRowSelection) => {
      setRowSelection(newRowSelection);
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableRowSelection: true,
    enableColumnFilters: false,
    enableGlobalFilter: true,
    enableHiding: false,
    enableRowActions: true,
    enableTopToolbar: false,
    enableFacetedValues: true,
    selectAllMode: "page",
    paginationDisplayMode: "pages",
    positionActionsColumn: "last",
    globalFilterFn: "fuzzy",
    renderRowActions: ({ table, row }) => (
      <RowActions row={row} userPermissions={userPermissions} disabled={table.getSelectedRowModel().rows.length > 0} />
    ),

    filterFns: {
      arrayIncludes: (row: Row<ApplicationListItem>, columnId: string, filterValue: string[] | null) =>
        filterValue === null || filterValue?.length === 0 || filterValue?.includes(row.getValue(columnId)),
    },
    muiTableContainerProps: {
      id: "application_list",
    },
    muiTableBodyRowProps: ({ row }) => ({
      id: `application_item_${row.original.id}`,
      className: "applicaion_item",
    }),
    muiTableBodyCellProps: ({ cell, row, table }) => ({
      sx: (theme) => ({
        ...theme.typography.body,
        color: theme.palette.text.mainInfo,
        cursor: "pointer",
      }),
      onClick: () => {
        if (cell.id !== "mrt-row-select" && !cell.id.includes("mrt-row-actions")) {
          onRowClick(table, row.original.id);
        }
      },
    }),
    muiTableHeadCellProps: {
      sx: (theme) => ({
        ...theme.typography.body,
        color: theme.palette.color.BASE[500],
      }),
    },
    muiBottomToolbarProps: {
      sx: (theme) => ({
        [`& .${tablePaginationClasses.root} label`]: {
          ...theme.typography.tags,
          color: theme.palette.color.BASE[800],
        },
        [`& .${tablePaginationClasses.root} .MuiSelect-select`]: {
          ...theme.typography.tagsStrong,
          color: theme.palette.color.BASE[800],
        },
      }),
    },
    muiPaginationProps: {
      color: "deepPurple",
      rowsPerPageOptions: [10, 25, 50],
    },
  });

  const { setColumnOrder } = table;

  // ensure that the columns are in the right order
  useEffect(() => {
    setColumnOrder(["mrt-row-select", ...columns.map((column) => column.id!), "mrt-row-actions"]);
  }, [columns, setColumnOrder]);

  return (
    <Box display="flex" flexDirection="column" gap={(theme) => theme.spacings[12]}>
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacings[12]}>
        <PageTitle>{sourcingCampaign.name}</PageTitle>
      </Box>
      {table.getColumn("status") && <ApplicationsStatusFilter table={table} />}
      <Box id="filter_list" display="flex" flexDirection="row" flexWrap="wrap" gap={(theme) => theme.spacings[12]}>
        <GroupedActions table={table} campaignID={id as string} />
        <MultiselectFilters table={table} columns={filtreableColumns} columnsVisibility={columnVisibility} />
        <Box display="flex" flexDirection="row" gap={(theme) => theme.spacings[12]}>
          <AdvancedFiltersAction table={table} />
          <GlobalFilter table={table} />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap={(theme) => theme.spacings[8]} flexWrap="wrap">
        <FiltersTags table={table} columns={filtreableColumns} columnsVisibility={columnVisibility} />
      </Box>
      <MaterialReactTable table={table} />
    </Box>
  );
};

import { FC, useCallback, useEffect, useState } from "react";

import { orderBy } from "lodash";
import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import {
  Cancel as CancelIcon,
  Description as DescriptionIcon,
  HourglassTop as HourglassTopIcon,
  PersonAdd as PersonAddIcon,
  PersonSearch as PersonSearchIcon,
  Phone as PhoneIcon,
  Star as StarIcon,
  Today as TodayIcon,
  Work as WorkIcon,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { ApplicationListItem } from "@typings";

type StatusFilter = {
  label: string;
  icon: JSX.Element;
};

const STATUSES_FILTER: Record<string, StatusFilter> = {
  all: {
    label: "all",
    icon: <PersonAddIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />,
  },
  new: {
    label: "new",
    icon: (
      <PersonSearchIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />
    ),
  },
  "did not answer": {
    label: "did not answer",
    icon: <PhoneIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />,
  },
  "validation pending": {
    label: "validation pending",
    icon: (
      <HourglassTopIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />
    ),
  },
  interview: {
    label: "interview",
    icon: <TodayIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />,
  },
  offer: {
    label: "offer",
    icon: <DescriptionIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />,
  },
  accepted: {
    label: "accepted",
    icon: <WorkIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />,
  },
  refused: {
    label: "refused",
    icon: <CancelIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />,
  },
  "added to talent pool": {
    label: "added to talent pool",
    icon: <StarIcon sx={(theme) => ({ width: "1.5rem", height: "1.5rem", fill: theme.palette.text.lowInfo })} />,
  },
};

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
};

export const ApplicationsStatusFilter: FC<Props> = ({ table }) => {
  loadTranslations("application-status");

  const { getColumn } = table;

  const { data: statuses } = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(),
    refetchOnWindowFocus: true,
    gcTime: Infinity,
    select: (result) => orderBy(result, ["rank"]),
  });

  const column = getColumn("status");

  const currentStatusFilter = column.getFilterValue() as string;
  const statusFacetedValues = column.getFacetedUniqueValues();

  const [filterValue, setFilterValue] = useState<string | null>(currentStatusFilter);

  const getStatusCount = useCallback(
    (status) => {
      let count = 0;
      if (status === "all") {
        statusFacetedValues.forEach((value) => {
          count += value;
        });
      } else {
        count = statusFacetedValues.get(status) || 0;
      }

      return count;
    },
    [statusFacetedValues],
  );

  const onFilterChange = useCallback((status: string) => {
    if (status === "all") {
      setFilterValue(null);
      return;
    }
    setFilterValue(status);
  }, []);

  useEffect(() => {
    column.setFilterValue(filterValue);
  }, [filterValue]);

  return (
    <Box id="status_card_list" display="flex" flexDirection="row" gap={(theme) => theme.spacings[12]} flex="1 1 0px">
      <StatusFilterItem
        id="status_card_all"
        key={"all"}
        status={STATUSES_FILTER["all"]}
        onClick={() => filterValue !== "all" && onFilterChange("all")}
        active={!filterValue}
        count={getStatusCount("all")}
      />
      {statuses?.map((status) => (
        <StatusFilterItem
          id={`status_card_${status.label}`}
          key={status.id}
          status={STATUSES_FILTER[status.label]}
          onClick={() => filterValue !== status.label && onFilterChange(status.label)}
          active={filterValue === status.label}
          count={getStatusCount(status.label)}
        />
      ))}
    </Box>
  );
};

type StatusFilterItemProps = {
  id?: string;
  status: StatusFilter;
  onClick: () => void;
  active: boolean;
  count: number;
};

const StatusFilterItem: FC<StatusFilterItemProps> = ({ id, status, onClick, active, count }) => {
  const { t } = useTranslation("application-status");

  return (
    <Box
      id={id}
      onClick={onClick}
      flex={1}
      className={active ? "active" : ""}
      sx={(theme) => ({
        display: "inline-block",
        cursor: "pointer",
        borderRadius: theme.radius[2],
        border: `1px solid ${theme.palette.color.BASE[300] as string}`,
        backgroundColor: theme.palette.color.BASE[0],
        color: theme.palette.text.mainInfo,
        padding: "1rem",
        "&:hover, &.active": {
          backgroundColor: theme.palette.color.deepPurple,
          borderColor: theme.palette.color.deepPurple,
        },
        "&:hover .MuiTypography-root, &.active .MuiTypography-root": {
          color: `${theme.palette.color.BASE[0] as string} !important`,
        },
        "&:hover svg, &.active svg": {
          fill: theme.palette.color.BASE[0],
        },
      })}
    >
      <Box>
        <Box display="flex" flexDirection="row" alignItems="center" gap="0.5rem">
          {status.icon}
          <Typography variant="tagsStrong" whiteSpace="nowrap">
            {t(status.label)}
          </Typography>
        </Box>
        <Typography variant="tagsStrong" color={(theme) => theme.palette.text.mediumInfo}>
          {count}
        </Typography>
      </Box>
    </Box>
  );
};

import { useMemo } from "react";

import { ApplicationApi, AuthProxyAPI, JobsApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";
import { Application, ApplicationListItem, CandidatesObject } from "@typings";

type Params = {
  campaignId: string;
  organizationName: string;
};
export const useFetchCampaignApplicationsList = ({ organizationName, campaignId }: Params) => {
  const applicationsQuery = useQuery<Application[], unknown>({
    queryKey: [QUERY_KEYS.CAMPAIGN_APPLICATIONS, campaignId],
    queryFn: () => ApplicationApi.list({ campaign_id: campaignId }),
    placeholderData: [],
  });

  const candidateIds = useMemo<string[]>(
    () => [...new Set(applicationsQuery.data?.map((application) => application.candidate_id) || [])],
    [applicationsQuery.data],
  );

  const candidatesQuery = useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_CANDIDATES, campaignId, candidateIds],
    queryFn: () => AuthProxyAPI.searchCandidates(candidateIds),
    enabled: !applicationsQuery.isLoading && candidateIds.length > 0,
    select: (candidates) =>
      candidates.reduce((res, candidate) => ({ ...res, [candidate.id]: candidate }), {} as CandidatesObject),
  });

  const jobsQuery = useQuery({
    queryKey: [QUERY_KEYS.JOBS, organizationName, campaignId],
    queryFn: () => JobsApi.search({ organization_name: organizationName, campaign_id: [campaignId] }),
    select: (jobs) => jobs.reduce((res, job) => ({ ...res, [job.id]: job }), {}),
  });

  const applications = useMemo((): ApplicationListItem[] => {
    if (!applicationsQuery.data) {
      return [];
    }

    return applicationsQuery.data.map((application) => ({
      id: application.id,
      organization_name: application.organization_name,

      campaign_id: application.campaign_id,

      status: application.status,
      status_reason: application.status_reason,
      last_interaction_date: application.last_interaction_date,
      created_at: application.created_at,
      last_opened_at: application.last_opened_at,

      candidate: candidatesQuery.data?.[application.candidate_id],
      job: jobsQuery.data?.[application.job_id],

      answers: application.answers,
    }));
  }, [applicationsQuery.data, candidatesQuery.data, jobsQuery.data]);

  return {
    applications,
    isPending: applicationsQuery.isPending || candidatesQuery.isPending || jobsQuery.isPending,
    isLoading: applicationsQuery.isLoading || candidatesQuery.isLoading || jobsQuery.isLoading,
    isSuccess: applicationsQuery.isSuccess && candidatesQuery.isSuccess,
  };
};

import moment from "moment";

import { MessagingHistory, MessagingReminder, MessagingTrigger, RecruiterReminder } from "@typings";

import { messagingAPI } from "./api";

interface GetHistoriesQueryParams {
  application_id: string;
}
interface GetRemindersQueryParams {
  application_id: string;
}

interface GetTriggersQueryParams {
  campaign_id: string;
}

const getHistories = async (params: GetHistoriesQueryParams): Promise<Array<MessagingHistory>> => {
  const { data } = await messagingAPI.get(`/histories`, { params });
  return data;
};

const getReminders = async (params: GetRemindersQueryParams): Promise<Array<MessagingReminder>> => {
  const { data } = await messagingAPI.get(`/reminders`, { params });
  return data;
};

const getTriggers = async (params: GetTriggersQueryParams): Promise<Array<MessagingTrigger>> => {
  const { data } = await messagingAPI.get(`/triggers`, { params });
  return data;
};

interface ListRecruiterReminderQueryParams {
  applicationID: string;
}

interface RecruiterReminderData {
  recruiter_id: string;
  comment: string;
  date: string;
  time: string;
  created_at: string;
}

const listRecruiterReminder = async (params: ListRecruiterReminderQueryParams): Promise<Array<RecruiterReminder>> => {
  const { data } = await messagingAPI.get<RecruiterReminderData[]>(
    `/recruiter_reminders?application_id=${params.applicationID}`,
  );

  return data.map(
    (reminderData): RecruiterReminder => ({
      application_id: params.applicationID,
      recruiter_id: reminderData.recruiter_id,
      date: moment(reminderData.date),
      time: moment(reminderData.time, "HH:mm:ss"),
      comment: reminderData.comment,
      created_at: reminderData.created_at,
    }),
  );
};

const createRecruiterReminder = async (params: RecruiterReminder): Promise<void> => {
  const { data } = await messagingAPI.post("/recruiter_reminders", {
    application_id: params.application_id,
    comment: params.comment,

    date: params.date.format("YYYY-MM-DD"),
    time: params.time.format("HH:mm"),
  });

  return data;
};

export const MessagingApi = {
  getHistories,
  getReminders,
  getTriggers,
  listRecruiterReminder,
  createRecruiterReminder,
};

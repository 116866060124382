import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: #fff;
  box-shadow:
    0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 12px;

  width: 456px;
  padding: 24px;
  font-weight: 400;
`;

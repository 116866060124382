import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type ApplicationsStore = {
  currentCampaignID: string | null;
  currentApplicationID: string | null;
  applicationsID: string[];
  setCurrentCampaignID: (campaignID: string | null) => void;
  setCurrentApplicationID: (applicationID: string | null) => void;
  setApplicationsID: (applicationsID: string[]) => void;
};

export const useApplicationsStore = create<ApplicationsStore>()(
  persist(
    (set) => ({
      applicationsID: [],
      currentApplicationID: null,
      currentCampaignID: null,
      setCurrentCampaignID: (campaignID) => set({ currentCampaignID: campaignID }),
      setApplicationsID: (applicationsID) => set({ applicationsID }),
      setCurrentApplicationID: (applicationID) => set({ currentApplicationID: applicationID }),
    }),
    {
      name: "campaign-applications-view",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

import { FC, ReactElement, useMemo, useState } from "react";

import { ApplicationInterviewModal, DeleteInterviewModal } from "components/application/application-interview-modals";
import moment from "moment";

import { InterviewApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";
import { Interview, InterviewsConfiguration, SourcingCampaign } from "@typings";

interface Props {
  interviewConfiguration: InterviewsConfiguration;
  sourcingCampaign: SourcingCampaign;
  applicationID: string;
  renderCreate?: ({ onClick, disabled }: { onClick: () => void; disabled: boolean }) => ReactElement;
  renderDelete?: ({ onClick }: { onClick: () => void }) => ReactElement;
}

export const InterviewAction: FC<Props> = ({
  sourcingCampaign,
  applicationID,
  interviewConfiguration,
  renderCreate,
  renderDelete,
}) => {
  const [createInterviewModalOpen, setCreateInterviewModalOpen] = useState(false);
  const [deleteInterviewModalOpen, setDeleteInterviewModalOpen] = useState(false);

  const interviewQuery = useQuery({
    queryKey: [QUERY_KEYS.INTERVIEW, applicationID],
    queryFn: () => InterviewApi.getInterview(applicationID),
  });

  const interview = useMemo<Interview | undefined>(() => {
    // get the active interview
    return interviewQuery.data?.find((interview) => !interview.deleted && interview.getInterviewEndDate() > moment());
  }, [interviewQuery.data]);

  const handleCreate = () => {
    setCreateInterviewModalOpen(true);
  };

  const handleDelete = () => {
    setDeleteInterviewModalOpen(true);
  };

  return (
    <>
      {(!interview || interview.deleted || !renderDelete) && renderCreate && (
        <>
          {renderCreate({
            onClick: handleCreate,
            disabled: interview !== undefined,
          })}
          <ApplicationInterviewModal
            open={createInterviewModalOpen}
            onConfirm={() => setCreateInterviewModalOpen(false)}
            onCancel={() => setCreateInterviewModalOpen(false)}
            campaignId={sourcingCampaign.campaign_id}
            applicationIDs={[applicationID]}
            interviewConfiguration={interviewConfiguration}
          />
        </>
      )}

      {interview && !interview.deleted && renderDelete && (
        <>
          {renderDelete({
            onClick: handleDelete,
          })}
          <DeleteInterviewModal
            open={deleteInterviewModalOpen}
            setOpen={setDeleteInterviewModalOpen}
            interview={interview}
          />
        </>
      )}
    </>
  );
};

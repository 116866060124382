import { useCallback, useMemo, useState } from "react";

import { AxiosError } from "axios";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, Typography } from "@mui/material";

import { ControlledTextField, Form } from "@work4Labs/design-system";

import { userApi } from "@api";
import { SettingsSaveButton } from "@components";
import { loadTranslations } from "@lib";
import { useMutation } from "@tanstack/react-query";

import { yupResolver } from "@hookform/resolvers/yup";

const usePasswordValidationSchema = (email: string) => {
  const { t } = useTranslation(["profile-validation"]);
  loadTranslations("profile-validation");

  return useMemo(
    () =>
      yup.object().shape({
        currentPassword: yup.string().required(t("profile-validation:password.required")),

        newPassword: yup
          .string()
          .required(t("profile-validation:newPassword.required"))
          .min(12, t("profile-validation:global.minCharacters", { count: 12 }))
          .matches(RegExp("[a-z]"), t("profile-validation:global.requires.lowercase"))
          .matches(RegExp("[A-Z]"), t("profile-validation:global.requires.uppercase"))
          .matches(RegExp("\\d"), t("profile-validation:global.requires.number"))
          .matches(RegExp('[!@#$%^&*(),.?":{}|<>]'), t("profile-validation:global.requires.special"))
          .notOneOf([yup.ref("currentPassword")], t("profile-validation:newPassword.sameAsOld")),

        passwordConfirmation: yup
          .string()
          .oneOf([yup.ref("newPassword")], t("profile-validation:newPassword.mismatchValidation")),
      }),
    [email, t],
  );
};

interface PasswordFormData {
  currentPassword: string;
  newPassword: string;
  passwordConfirmation: string;
}

export const PasswordForm = () => {
  const { t } = useTranslation(["profile-settings"]);
  loadTranslations("profile-settings");

  const { data: session } = useSession();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

  const validationSchema = usePasswordValidationSchema(session?.user.email ?? "");

  const passwordForm = useForm<PasswordFormData>({
    resolver: yupResolver(validationSchema, { abortEarly: false }),
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
    },
  });

  const updatePasswordMutation = useMutation({
    mutationFn: userApi.updatePassword,
    onSuccess: () => {
      toast.success(t("profile-settings:account.password.update.success"));
    },
    onError: (err: AxiosError) => {
      if (err.response?.status === 500) {
        toast.error(t("profile-settings:account.password.update.usedPassword"));
      } else if (err.response?.status === 401) {
        toast.error(t("profile-settings:account.password.update.badCredentials"));
      } else {
        toast.error(t("profile-settings:account.password.update.error"));
      }
    },
  });

  const onPasswordSave = useCallback(
    (formData: PasswordFormData) => {
      updatePasswordMutation.mutate({
        old_password: formData.currentPassword,
        new_password: formData.newPassword,
        repeat_password: formData.passwordConfirmation,
      });
    },
    [updatePasswordMutation],
  );

  const canSave = session && passwordForm.formState.isValid && !updatePasswordMutation.isPending;

  return (
    <Form
      style={{ display: "flex", flexDirection: "column", gap: "var(--size-24)", alignItems: "stretch" }}
      submitHandler={onPasswordSave}
      methods={passwordForm}
    >
      <ControlledTextField
        error={passwordForm.formState.errors.currentPassword?.message}
        label={t("profile-settings:account.password.current")}
        name="currentPassword"
        disabled={session == null}
        fullWidth
        InputProps={{
          type: showCurrentPassword ? "text" : "password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                edge="end"
              >
                {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ControlledTextField
        error={passwordForm.formState.errors.newPassword?.message}
        label={t("profile-settings:account.password.new")}
        name="newPassword"
        disabled={session == null}
        fullWidth
        InputProps={{
          type: showNewPassword ? "text" : "password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowNewPassword(!showNewPassword)}
                edge="end"
              >
                {showNewPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ControlledTextField
        error={passwordForm.formState.errors.passwordConfirmation?.message}
        label={t("profile-settings:account.password.validate")}
        name="passwordConfirmation"
        disabled={session == null}
        fullWidth
        InputProps={{
          type: showPasswordConfirmation ? "text" : "password",
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                edge="end"
              >
                {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {passwordForm.formState.errors.root ? (
        <Typography color="var(--color-palette-alert-red-400)">{passwordForm.formState.errors.root.message}</Typography>
      ) : null}
      <SettingsSaveButton disabled={!canSave}>{t("profile-settings:account.password.save")}</SettingsSaveButton>
    </Form>
  );
};

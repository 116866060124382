import { FC } from "react";

import { TFunction, Trans, useTranslation } from "next-i18next";

import { AssignmentTurnedIn as AssignmentTurnedInIcon, MessageOutlined as MessageIcon } from "@mui/icons-material";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box } from "@mui/material";

import { AuthProxyAPI } from "@api";
import { QUERY_KEYS, TEXT_COLOR } from "@constants";
import { loadTranslations } from "@lib";
import { useQuery } from "@tanstack/react-query";
import { AriaProps, AuthProxyUser, DocumentsLogs, DocumentsLogsType } from "@typings";

import { CommentListSubInfo, TimelinePerson } from "./style";

interface Props extends AriaProps {
  documentsLogs: DocumentsLogs;
}

export const DocumentLogsDetail: FC<Props> = ({ documentsLogs, role }) => {
  const { t } = useTranslation(["documents-logs-details"]);
  loadTranslations("documents-logs-details");

  const { data: user, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USERS, documentsLogs.user_id],
    queryFn: () => AuthProxyAPI.getUser(documentsLogs.user_id),
  });

  const getDocumentsLogKind = (logType: string) => {
    switch (logType) {
      case DocumentsLogsType.REQUEST: {
        return "request_type";
      }
      case DocumentsLogsType.AUTOMATION: {
        return "automation_type";
      }
      case DocumentsLogsType.UPLOAD: {
        return "upload_type";
      }
      case DocumentsLogsType.SELF_UPLOAD: {
        return "self_upload_type";
      }
      default: {
        return "";
      }
    }
  };

  return (
    <TimelineItem role={role}>
      <TimelineSeparator>
        {renderIcon(documentsLogs, user)}
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ position: "relative", top: "-18px" }}>
        {user && !isLoading && (
          <Box
            sx={{
              color: TEXT_COLOR.mainInfo,
              fontSize: "14px",
            }}
          >
            {renderMessage(documentsLogs, t, `${user?.first_name} ${user?.last_name}`)}
          </Box>
        )}
        <Box>
          <CommentListSubInfo
            created_at={documentsLogs.created_at}
            kind={t(getDocumentsLogKind(documentsLogs.log_type))}
          />
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};

const renderIcon = (documentsLogs: DocumentsLogs, user?: AuthProxyUser) => {
  if (documentsLogs.log_type === DocumentsLogsType.AUTOMATION) {
    return (
      <TimelineDot
        sx={(theme) => ({
          width: "36px",
          height: "36px",
          backgroundColor: theme.palette.grey[400],
        })}
      >
        <MessageIcon
          sx={(theme) => ({
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: theme.palette.grey[400],
          })}
        />
      </TimelineDot>
    );
  }
  if (documentsLogs.log_type === DocumentsLogsType.UPLOAD) {
    return (
      <TimelineDot
        sx={(theme) => ({
          width: "36px",
          height: "36px",
          backgroundColor: theme.palette.grey[400],
        })}
      >
        <AssignmentTurnedInIcon
          sx={(theme) => ({
            width: "1.5rem",
            height: "1.5rem",
            backgroundColor: theme.palette.grey[400],
          })}
        />
      </TimelineDot>
    );
  }
  if (
    documentsLogs.log_type === DocumentsLogsType.REQUEST ||
    documentsLogs.log_type === DocumentsLogsType.SELF_UPLOAD
  ) {
    return (
      <TimelinePerson userName={user ? `${user.first_name} ${user.last_name}` : undefined} userEmail={user?.email} />
    );
  }

  return "";
};

const renderMessage = (documentsLogs: DocumentsLogs, t: TFunction, username?: string) => {
  const documenType = t(documentsLogs.document_type);

  switch (documentsLogs.log_type) {
    case DocumentsLogsType.REQUEST: {
      return <Trans t={t} i18nKey="request" components={[<strong key={0} />]} values={{ username: username }} />;
    }
    case DocumentsLogsType.UPLOAD: {
      return <Trans t={t} i18nKey="upload" components={[<strong key={0} />]} values={{ documentType: documenType }} />;
    }
    case DocumentsLogsType.AUTOMATION: {
      return (
        <Trans t={t} i18nKey="automation" components={[<strong key={0} />]} values={{ documentType: documenType }} />
      );
    }
    case DocumentsLogsType.SELF_UPLOAD: {
      return (
        <Trans
          t={t}
          i18nKey="self_upload"
          components={[<strong key={0} />]}
          values={{ documentType: documenType, username: username }}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

import { Job } from "@typings";

import { jobsAPI } from "./api";

export const get = async (id: string): Promise<Job> => {
  const { data } = await jobsAPI.get(`/${id}`);
  return data;
};

export interface ListJobsParams {
  organization_name: string;
  job_id?: Array<string>;
  title?: Array<string>;
  city?: Array<string>;
  postal_code?: Array<string>;
  category?: Array<string>;
  api_ref?: Array<string>;
  contract?: Array<string>;
  campaign_id?: Array<string>;
}

export const search = async (params: ListJobsParams): Promise<Array<Job>> => {
  const { data } = await jobsAPI.get("/", {
    params,
  });
  return data;
};

export const JobsApi = {
  get,
  search,
};

import React, { FC, ReactElement, useMemo, useState } from "react";

import { useSession } from "next-auth/react";

import { DeleteApplicationUpdateModal } from "@components";
import { UserPermissions } from "@constants";
import { checkPermissions } from "@utils";

import { useUserPermissions } from "@hooks";

type Props = {
  applicationID: string;
  campaignID: string;
  render: ({ onClick }: { onClick: () => void }) => ReactElement;
};

export const DeleteApplicationAction: FC<Props> = ({ applicationID, campaignID, render }) => {
  const { data: session } = useSession();
  const userPermissions = useUserPermissions(session?.accessToken);

  const canDeleteApplication = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.ApplicationPermissions.Delete]),
    [userPermissions],
  );

  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      {canDeleteApplication && (
        <>
          {render({
            onClick: handleClick,
          })}

          <DeleteApplicationUpdateModal
            applicationIDs={[applicationID]}
            open={modalOpen}
            campaignID={campaignID}
            setOpen={setModalOpen}
            onSuccess={() => setModalOpen(false)}
          />
        </>
      )}
    </>
  );
};

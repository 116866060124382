import { Anonyme, AuthProxyUser, AuthProxyUsersList, Candidate } from "@typings";

import { candidatesAPI, usersAPI } from "./api";

const getUser = async (id: string): Promise<AuthProxyUser> => {
  const { data } = await usersAPI.get(`/${id}`);
  return data;
};

const listUsers = async (organization_name: string): Promise<AuthProxyUsersList> => {
  const { data } = await usersAPI.get("/", {
    params: { organization_name },
  });
  return data;
};

const getCandidate = async (id: string): Promise<Candidate> => {
  if (id === "") {
    return Anonyme;
  }

  const { data } = await candidatesAPI.get(`/${id}`);
  return data;
};

const searchCandidates = async (ids: Readonly<Array<string>>): Promise<Candidate[]> => {
  const { data } = await candidatesAPI.post("/search", ids);

  return data;
};

export const AuthProxyAPI = {
  getUser,
  listUsers,
  getCandidate,
  searchCandidates,
};

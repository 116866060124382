import { FC, useCallback } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Chip } from "@mui/material";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
  column: string;
};
export const FilterTags: FC<Props> = ({ table, column }) => {
  const filterValue: string[] = (table.getColumn(column).getFilterValue() as string[]) || [];
  const columnValues = table.getColumn(column).getFacetedUniqueValues();

  if (filterValue.length >= 8) {
    return <FilterTagGrouped table={table} column={column} values={filterValue} />;
  }

  return (
    <>
      {filterValue.map((value) => (
        <FilterTag key={value} table={table} column={column} value={value} count={columnValues.get(value) || 0} />
      ))}
    </>
  );
};

type FilterTagProps = {
  table: MRT_TableInstance<ApplicationListItem>;
  column: string;
  value: string;
  count: number;
};

const FilterTag: FC<FilterTagProps> = ({ table, column, value, count }) => {
  const { t } = useTranslation(["leads"]);
  loadTranslations("leads");

  const onDelete = useCallback(() => {
    table.getColumn(column).setFilterValue((prev) => prev.filter((v) => v !== value));
  }, [column, table, value]);

  return (
    <Chip
      label={`${column.startsWith("question_") ? column.split("question_")[1] : t(column)} : ${value} (${count})`}
      color="deepPurple"
      onDelete={onDelete}
      size="large"
    />
  );
};

type FilterTagGroupedProps = {
  table: MRT_TableInstance<ApplicationListItem>;
  column: string;
  values: string[];
};

const FilterTagGrouped: FC<FilterTagGroupedProps> = ({ table, column, values }) => {
  const { t } = useTranslation(["filter-tags", "leads"]);
  loadTranslations("filter-tags");
  loadTranslations("leads");

  const onDelete = useCallback(() => {
    table.getColumn(column).setFilterValue([]);
  }, [column, table]);

  return (
    <Chip
      label={
        column.startsWith("question_")
          ? column.split("question_")[1]
          : `${t(column, { ns: "leads" })} : ${t("options_selected", { count: values.length, ns: "filter-tags" })}`
      }
      color="deepPurple"
      onDelete={onDelete}
      size="large"
    />
  );
};

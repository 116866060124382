import { ApplicationApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";

export const useFetchApplicationStatuses = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.APPLICATIONS_STATUSES],
    queryFn: () => ApplicationApi.listStatuses(),
    refetchOnWindowFocus: true,
    gcTime: Infinity,
  });
};

import { FC, MouseEvent, useCallback, useEffect, useState } from "react";

import { AxiosError } from "axios";
import { useTranslation } from "next-i18next";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { ArrowDropDown as ArrowDropDownIcon, PersonAdd as PersonAddIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";

import { Form, Modal, Select, TextField } from "@work4Labs/design-system";

import { updateRoleBody, userApi } from "@api";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthProxyUsersListItem, UserRoles } from "@typings";

interface EditUserRoleFormData {
  role: "admin" | "recruiter";
}

const EditUserRoleModal: FC<{ user?: AuthProxyUsersListItem; opened: boolean; close: () => void }> = ({
  user,
  opened,
  close,
}) => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const queryClient = useQueryClient();

  const form = useForm<EditUserRoleFormData>({
    shouldUnregister: false,
    mode: "onChange",
    defaultValues: {
      role: user?.role ?? UserRoles.recruiter,
    },
  });

  useEffect(() => {
    form.reset({ role: user?.role ?? UserRoles.recruiter });
  }, [form, user?.role]);

  const updateUserRoleMutation = useMutation<void, AxiosError, Partial<updateRoleBody>>({
    mutationFn: userApi.updateRole,
    onSuccess: () => {
      toast.success(
        t("organization-settings:users.modals.editRoles.updated", {
          firstName: user?.first_name,
          lastName: user?.last_name,
        }),
      );

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.USERS],
        })
        .catch(() => {})
        .finally(close);
    },
    onError: (err) => {
      switch (err.response?.status) {
        case 403:
          toast.error(t("organization-settings:users.modals.editRoles.notAllowed"));
          break;
        default:
          toast.error(t("organization-settings:users.modals.editRoles.error"));
      }
    },
  });

  const onClose = useCallback(() => {
    form.reset({ role: user?.role ?? UserRoles.recruiter });
    close();
  }, [close, form, user?.role]);

  const updateUserRole = useCallback(
    (formData: EditUserRoleFormData) => {
      if (user?.id == null) return;

      updateUserRoleMutation.mutate({ id: user.id, body: { role: formData.role } });
    },
    [updateUserRoleMutation, user?.id],
  );

  const watchRole = form.watch("role");

  const canSave = user && watchRole != user.role && !updateUserRoleMutation.isPending;

  return (
    <Modal
      options={{
        confirmProps: { disabled: !canSave },
      }}
      isOpen={opened}
      confirmText={t("organization-settings:users.modals.editRoles.confirm")}
      cancelText={t("organization-settings:users.modals.editRoles.cancel")}
      onConfirm={() => {
        form
          .trigger()
          .then((isValid) => {
            if (isValid) {
              form.handleSubmit(updateUserRole)();
            }
          })
          .catch(() => {});
      }}
      onClose={onClose}
      modalTitle={t("organization-settings:users.modals.editRoles.modalTitle")}
      title={t("organization-settings:users.modals.editRoles.title", {
        firstName: user?.first_name,
        lastName: user?.last_name,
      })}
      modalIcon={<PersonAddIcon />}
    >
      <Form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: "var(--space-16)",
          width: "100%",
        }}
        methods={form}
        submitHandler={updateUserRole}
      >
        <Controller
          control={form.control}
          name="role"
          render={({ field }) => (
            <Select
              required
              error={form.formState.errors.role?.message}
              selectProps={{ fullWidth: true }}
              value={field.value}
              onChange={(event) => field.onChange(event.target.value)}
              label={t("organization-settings:users.modals.editRoles.role.label")}
              renderValue={(selected) => t(`organization-settings:users.modals.editRoles.role.values.${selected}`)}
            >
              {Object.values(UserRoles).map((role) => (
                <MenuItem key={role} value={role}>
                  {t(`organization-settings:users.modals.editRoles.role.values.${role}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Form>
    </Modal>
  );
};

const DeleteUserModal: FC<{ user?: AuthProxyUsersListItem; opened: boolean; close: () => void }> = ({
  user,
  opened,
  close,
}) => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const queryClient = useQueryClient();

  const deleteUserMutation = useMutation<void, AxiosError, Partial<updateRoleBody>>({
    mutationFn: userApi.updateRole,
    onSuccess: () => {
      toast.success(t("organization-settings:users.modals.deleteUser.deleted"));

      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.USERS],
        })
        .catch(() => {});

      close();
    },
    onError: (err) => {
      switch (err.response?.status) {
        case 403:
          toast.error(t("organization-settings:users.modals.deleteUser.notAllowed"));
          break;
        default:
          toast.error(t("organization-settings:users.modals.deleteUser.error"));
      }
    },
  });

  return (
    <Modal
      isOpen={opened}
      confirmText={t("organization-settings:users.modals.deleteUser.confirm")}
      cancelText={t("organization-settings:users.modals.deleteUser.cancel")}
      onConfirm={() => {
        deleteUserMutation.mutate({ id: user?.id, body: { enabled: false } });
      }}
      onClose={close}
      modalTitle={t("organization-settings:users.modals.deleteUser.modalTitle")}
      title={t("organization-settings:users.modals.deleteUser.title")}
      modalIcon={<PersonAddIcon />}
    >
      <TextField
        value={`${user?.first_name} ${user?.last_name}`}
        disabled
        fullWidth
        label={t("organization-settings:users.modals.deleteUser.selected")}
      />
    </Modal>
  );
};

export const Actions: FC<{ user?: AuthProxyUsersListItem }> = ({ user }) => {
  const { t } = useTranslation(["organization-settings"]);
  loadTranslations("organization-settings");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const [updateUserRoleModalOpened, setUpdateUserRoleModalOpened] = useState(false);
  const [deleteUserRoleModalOpened, setDeleteUserRoleModalOpened] = useState(false);

  return (
    <>
      <Button
        id="actions-button"
        aria-controls={open ? "actions-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        variant="contained"
        color="deepPurple"
        sx={{
          border: 0,
        }}
      >
        {t("organization-settings:users.actions.label")}
      </Button>

      <Menu
        id="actions-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "actions-button",
        }}
      >
        <MenuItem onClick={() => setUpdateUserRoleModalOpened(true)}>
          <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
            {t("organization-settings:users.actions.values.edit")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setDeleteUserRoleModalOpened(true)}>
          <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
            {t("organization-settings:users.actions.values.delete")}
          </Typography>
        </MenuItem>
      </Menu>

      <EditUserRoleModal
        user={user}
        opened={updateUserRoleModalOpened}
        close={() => {
          setUpdateUserRoleModalOpened(false);
          handleClose();
        }}
      />

      <DeleteUserModal
        user={user}
        opened={deleteUserRoleModalOpened}
        close={() => {
          setDeleteUserRoleModalOpened(false);
          handleClose();
        }}
      />
    </>
  );
};

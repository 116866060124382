import { ClickToCallAPI } from "@api";
import { PHONE_VALIDATION_STATUS, QUERY_KEYS } from "@constants";
import { UseQueryResult, useQueries, useQuery } from "@tanstack/react-query";
import { NumberVerificationResponse } from "@typings";

export const usePhoneNumberVerificationStatus = (phone: string) => {
  const numberStatusQuery = useQuery({
    queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, phone],
    queryFn: () => ClickToCallAPI.getNumberVerificationStatus(phone),
    enabled: !!phone,
  });

  return {
    isLoading: numberStatusQuery.isLoading,
    error: numberStatusQuery.error,
    status: numberStatusQuery.data?.status || PHONE_VALIDATION_STATUS.UNVERIFIED,
    verified: numberStatusQuery.data?.status === PHONE_VALIDATION_STATUS.VERIFIED,
  };
};

export interface NumbersVerificationStatuses {
  data: Record<string, string>;
}

const phoneQueriesCombinator = (
  results: UseQueryResult<NumberVerificationResponse, Error>[],
): NumbersVerificationStatuses => ({
  data: results.reduce(
    (acc, result) => (result.isSuccess ? { ...acc, [result.data.phone]: result.data.status } : acc),
    {},
  ),
});

export const usePhoneNumbersVerificationsStatuses = (phones: string[]) => {
  return useQueries({
    queries: phones.map((phone) => ({
      queryKey: [QUERY_KEYS.CLICK_TO_CALL_NUMBER_VERIFICATION_STATUS, phone],
      queryFn: () => ClickToCallAPI.getNumberVerificationStatus(phone),
    })),
    combine: phoneQueriesCombinator,
  });
};

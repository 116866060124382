import React, { FC, useCallback, useMemo, useState } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import {
  ArrowDropDown as ArrowDropDownIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";
import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import Autocomplete, { AutocompleteCloseReason, autocompleteClasses } from "@mui/material/Autocomplete";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

interface PopperComponentProps {
  anchorEl?: unknown;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  width: "auto !important",
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props: PopperComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"}`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type FilterValue = { count: number; value: string };

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
  columnID: string;
};

export const MultiselectFilter: FC<Props> = ({ table, columnID }) => {
  const { t } = useTranslation(["leads", "multiselect-filter"]);
  loadTranslations("leads");
  loadTranslations("multiselect-filter");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pendingValue, setPendingValue] = useState<FilterValue[]>([]);

  const open = Boolean(anchorEl);

  const { getColumn } = table;

  const column = getColumn(columnID);

  const optionsValues = column.getFacetedUniqueValues();

  const options = useMemo(() => {
    const result: Array<FilterValue> = [];
    optionsValues.forEach((count, value) => {
      result.push({ count, value });
    });
    return result.sort((a, b) => (a.value > b.value ? 1 : -1));
  }, [optionsValues]);

  const value = useMemo(() => {
    const filterValue: string[] = (column.getFilterValue() as string[]) || [];
    return options.filter((option) => filterValue.includes(option.value));
  }, [column, options]);

  const handleClose = useCallback(() => {
    setPendingValue([]);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (open) {
        handleClose();
        return;
      }
      setPendingValue(value);
      setAnchorEl(event.currentTarget);
    },
    [handleClose, open, value],
  );

  const handleApply = useCallback(() => {
    column.setFilterValue(pendingValue.map((v) => v.value));
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl, column, pendingValue]);

  const id = open ? `multiselect-filter-${columnID}` : undefined;

  return (
    <React.Fragment>
      <Button
        id={`filter_item_${columnID}`}
        disableRipple
        aria-describedby={id}
        onClick={handleClick}
        variant="outlined"
        color="primary"
      >
        <span>
          {t("filter_by", { ns: "multiselect-filter" })}{" "}
          {columnID.startsWith("question_") ? columnID.split("question_")[1] : t(columnID, { ns: "leads" })}
        </span>
        <ArrowDropDownIcon />
      </Button>
      <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose}>
          <Box p={(theme) => theme.spacings[8]}>
            <Autocomplete
              open
              multiple
              disableClearable
              onClose={(_event, reason: AutocompleteCloseReason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  ((event as React.KeyboardEvent).key === "Backspace" ||
                    (event as React.KeyboardEvent).key === "Delete") &&
                  reason === "removeOption"
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box display="flex" alignItems="center">
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    <Typography variant="body">
                      {option.value ? option.value : t("empty", { ns: "multiselect-filter" })} ({option.count})
                    </Typography>
                  </Box>
                </li>
              )}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              options={options}
              getOptionLabel={(option) => option.value}
              renderInput={(params) => (
                <TextField
                  size="small"
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={t("quick_search", { ns: "multiselect-filter" })}
                  sx={(theme) => ({
                    width: "100%",
                    backgroundColor: theme.palette.color.BASE[100],
                  })}
                />
              )}
            />
            <Box
              display="flex"
              justifyContent="end"
              mt={(theme) => theme.spacings[8]}
              gap={(theme) => theme.spacings[8]}
            >
              <Button onClick={handleClose} variant="outlined">
                {t("actions.cancel", { ns: "multiselect-filter" })}
              </Button>
              <Button onClick={handleApply} variant="contained" color="deepPurple">
                {t("actions.apply", { ns: "multiselect-filter" })}
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
};

import { FC, useCallback, useMemo } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Chip } from "@mui/material";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

import { FilterTags } from "./filter-tags";

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
  columns: string[];
  columnsVisibility: Record<string, boolean>;
};
export const FiltersTags: FC<Props> = ({ table, columns, columnsVisibility }) => {
  const { t } = useTranslation(["filter-tags"]);
  loadTranslations("filter-tags");

  const { getColumn, getState } = table;
  const { columnFilters } = getState();

  const onFilterReset = useCallback(() => {
    columns.forEach((column) => {
      getColumn(column).setFilterValue([]);
    });
  }, [columns, getColumn]);

  const isActiveFilter = useMemo(() => {
    return columns.some(
      (column) => ((columnFilters.find((filter) => filter.id === column)?.value as string[]) || [])?.length > 0,
    );
  }, [columnFilters, columns]);

  return (
    <>
      {columns.map((column) =>
        getColumn(column) && columnsVisibility[column] ? (
          <FilterTags key={column} table={table} column={column} />
        ) : (
          <></>
        ),
      )}
      {isActiveFilter && <Chip label={t("reset_filter")} color="deepPurple" onDelete={onFilterReset} size="large" />}
    </>
  );
};

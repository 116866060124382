import { AuthProxyAPI } from "@api";
import { QUERY_KEYS } from "@constants";
import { useQuery } from "@tanstack/react-query";

export const useOrganizationUsers = (userGroup: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS.USERS, userGroup],
    queryFn: () => AuthProxyAPI.listUsers(userGroup),
  });
};

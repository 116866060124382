import { FC, useState } from "react";

import { useTranslation } from "next-i18next";
import "react-multi-email/dist/style.css";

import { IosShare } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

import { ShareApplicationModal } from "@components";
import { loadTranslations } from "@lib";
import { Application } from "@typings";

interface Props {
  application: Application;
}

export const ApplicationShare: FC<Props> = ({ application }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box>
      <Button
        sx={{
          padding: "8px",
        }}
        onClick={() => {
          setModalOpen(true);
        }}
        color="secondary"
        variant="outlined"
        id="share_candidate_file"
      >
        <IosShare
          sx={{
            width: "24px",
            height: "24px",
            marginRight: "2.5px",
          }}
        />
        {t("share")}
      </Button>
      <ShareApplicationModal applicationID={application.id} open={modalOpen} setOpen={setModalOpen} />
    </Box>
  );
};

import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  ExpandMore as ExpandMoreIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Checkbox as MuiCheckbox,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";

import { InfoBox, Modal } from "@work4Labs/design-system";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

const EXCLUDED_COLUMNS = ["mrt-row-select", "mrt-row-actions", "name"];

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Option = {
  value: string;
  label: string;
};

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  table: MRT_TableInstance<ApplicationListItem>;
};

export const AdvancedFiltersModal: FC<Props> = ({ open, setOpen, table }) => {
  const { t } = useTranslation(["applications-advanced-filters", "leads"]);
  loadTranslations("applications-advanced-filters");
  loadTranslations("leads");

  const theme = useTheme();

  const [questionsFilter, setQuestionsFilter] = useState("");

  const { setColumnVisibility, getAllColumns } = table;
  const baseFiltersColumns = getAllColumns().filter(
    (column) => !EXCLUDED_COLUMNS.includes(column.id) && !column.id.startsWith("question_"),
  );

  const questionsColumns = getAllColumns().filter((column) => column.id.startsWith("question_"));

  const questionOptions = useMemo<Option[]>(() => {
    return questionsColumns.map((column) => ({
      value: column.id,
      label: column.columnDef.header,
    }));
  }, [questionsColumns]);

  const filteredQuestionsOptions = useMemo(() => {
    return questionsFilter
      ? questionOptions.filter((option) => option.label.toLowerCase().includes(questionsFilter.toLowerCase()))
      : questionOptions;
  }, [questionOptions, questionsFilter]);

  const [enabledBaseFilters, setEnabledBaseFilters] = useState(
    baseFiltersColumns.reduce((acc, column) => ({ ...acc, [column.id]: column.getIsVisible() }), {}),
  );

  const [enabledQuestionFilters, setEnabledQuestionFilters] = useState(
    questionsColumns.reduce((acc, column) => ({ ...acc, [column.id]: column.getIsVisible() }), {}),
  );

  const onConfirm = useCallback(() => {
    baseFiltersColumns.forEach((column) => {
      if (!enabledBaseFilters[column.id]) {
        column.setFilterValue(null);
      }
    });
    questionsColumns.forEach((column) => {
      if (!enabledQuestionFilters[column.id]) {
        column.setFilterValue(null);
      }
    });
    setColumnVisibility({ ...enabledBaseFilters, ...enabledQuestionFilters });
    setOpen(false);
  }, [baseFiltersColumns, enabledBaseFilters, enabledQuestionFilters, questionsColumns, setColumnVisibility, setOpen]);

  const onCancel = () => {
    setOpen(false);
  };

  const customActions = (onConfirm: (() => void) | undefined, onClose: (() => void) | undefined) => {
    const buttonStyle = {
      fontSize: "1rem",
      fontWeight: "600",
      textTransform: "none",
      borderRadius: "8px",
    };
    return (
      <>
        <Button
          color="secondary"
          onClick={() => {
            onClose?.();
          }}
          sx={{
            ...buttonStyle,
            color: theme.palette.primary.main,
            backgroundColor: "white",
            border: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm?.();
          }}
          sx={{
            ...buttonStyle,
            color: "white",
            backgroundColor: theme.palette.color.deepPurple,
            "&:hover": {
              backgroundColor: theme.palette.color.deepPurple,
            },
          }}
        >
          {t("apply")}
        </Button>
      </>
    );
  };

  return (
    <Modal
      isOpen={open}
      onConfirm={onConfirm}
      onClose={onCancel}
      modalIcon={<FilterListIcon />}
      modalTitle={t("title")}
      title={t("existing_filters")}
      customActions={customActions}
    >
      <ThemeProvider theme={theme}>
        <Box>
          <InfoBox level={"info"}>
            <Typography variant="body" color={(theme) => theme.palette.color.BASE[800]}>
              {t("info_box")}
            </Typography>
          </InfoBox>
          <Typography variant="body" color={(theme) => theme.palette.color.BASE[800]}>
            {t("description")}
          </Typography>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="base-filters-content"
              id="base-filters-header"
            >
              <Typography variant="bodyStrong" color={(theme) => theme.palette.color.BASE[800]}>
                {t("base_filters")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {baseFiltersColumns.map((column) => (
                  <Grid item xs={6} key={column.id}>
                    <FormControlLabel
                      control={
                        <MuiCheckbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={enabledBaseFilters[column.id] ?? false}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setEnabledBaseFilters((prev) => ({ ...prev, [column.id]: event.target.checked }))
                          }
                        />
                      }
                      label={t(column.id, { ns: "leads" })}
                      componentsProps={{
                        typography: {
                          variant: "body",
                        },
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="qualifications-content"
              id="qualifications-header"
            >
              <Typography variant="bodyStrong" color={(theme) => theme.palette.color.BASE[800]}>
                {t("qualifications")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                size="small"
                autoFocus
                placeholder={t("quick_search")}
                sx={(theme) => ({
                  width: "100%",
                  backgroundColor: theme.palette.color.BASE[100],
                })}
                value={questionsFilter}
                onChange={(e) => setQuestionsFilter(e.target.value)}
              />
              <List
                sx={{
                  height: "18rem",
                  overflowY: "auto",
                }}
              >
                {filteredQuestionsOptions.map((option) => (
                  <ListItem key={option.value}>
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        control={
                          <MuiCheckbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={enabledQuestionFilters[option.value] ?? false}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              setEnabledQuestionFilters((prev) => ({ ...prev, [option.value]: event.target.checked }))
                            }
                          />
                        }
                        label={option.label}
                        componentsProps={{
                          typography: {
                            variant: "body",
                          },
                        }}
                      />
                    </Box>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Box>
      </ThemeProvider>
    </Modal>
  );
};

import { FC, SyntheticEvent, useCallback, useState } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { Box, Button, FormControlLabel, Grid } from "@mui/material";

import { Checkbox } from "@work4Labs/design-system";

import { ApplicationApi } from "@api";
import { ReactQuill } from "@components";
import { QUERY_KEYS } from "@constants";
import { loadTranslations } from "@lib";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Application, CommentKind } from "@typings";

import { RecruiterReminderCreationModal } from "./recruiter-reminder-modal";

type AddCommentTextAreaProps = {
  application: Application;
};

export const AddCommentTextArea: FC<AddCommentTextAreaProps> = ({ application }) => {
  const { t } = useTranslation(["comment-list", "dates"]);
  loadTranslations("comment-list");
  loadTranslations("dates");

  const [comment, setComment] = useState("");
  const [commentIsReminder, setCommentIsReminder] = useState(false);
  const [reminderModalOpened, setReminderModalOpened] = useState(false);

  const queryClient = useQueryClient();

  const commentMutation = useMutation({
    mutationFn: ApplicationApi.createComment,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [QUERY_KEYS.APPLICATIONS, application.id],
        })
        .catch(() => {});
      toast.success(t("comment_created"));
      setComment("");
    },
    onError: () => {
      toast.error(t("error_occurred"));
    },
  });

  const createComment = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      if (commentIsReminder) {
        setReminderModalOpened(true);
        return;
      }

      const content =
        comment.trim().replace(/(<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>)|(<img\b.*>)/gi, "") || "";

      const application_id = application.id;
      const createComment = {
        application_id,
        body: {
          content: content,
          is_bulk_action: false,
          kind: CommentKind.COMMENT,
        },
      };

      commentMutation.mutate(createComment);
    },
    [application.id, comment, commentIsReminder, commentMutation],
  );

  const onSuccess = useCallback(() => {
    setComment("");
    setCommentIsReminder(false);
  }, [setComment, setCommentIsReminder]);

  const closeModal = useCallback(() => {
    setReminderModalOpened(false);
  }, [setReminderModalOpened]);

  return (
    <Box
      id="comment_space"
      sx={{
        backgroundColor: "white",
        marginTop: "15px",
        height: "200px",
        marginBottom: "120px",
      }}
    >
      <ReactQuill
        id="comment-editor"
        style={{ backgroundColor: "white", height: "100%" }}
        className="--body-copy"
        theme="snow"
        value={comment}
        onChange={setComment}
        bounds="#comment-editor"
        modules={{
          toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
        }}
      />

      <Grid container sx={{ marginTop: "3.5rem", fontSize: "13px" }}>
        <Grid xs={4}>
          <Button
            aria-label="créer un commentaire"
            sx={(theme) => ({
              padding: "6px 18px",

              color: theme.palette.background.paper,
              background: theme.palette.primary[800],

              minWidth: "fit-content",
              width: "48px",
              height: "2rem",

              textTransform: "none",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: theme.palette.primary[900],
              },
              "&:disabled": {
                backgroundColor: "var(--color-palette-base-100)",
              },
            })}
            onClick={createComment}
            disabled={comment.trim().length === 0}
            id="save_comment"
          >
            {t("save")}
          </Button>
        </Grid>
        <Grid xs={8} sx={{ position: "relative" }}>
          <FormControlLabel
            value={t("make_it_reminder")}
            sx={{
              position: "absolute",
              right: 0,

              fontSize: "13px",
              margin: 0,
              "& .MuiTypography-root": {
                fontSize: "13px",
              },
              "& .MuiSvgIcon-root": { width: 36 },
            }}
            control={<Checkbox checked={commentIsReminder} onChange={() => setCommentIsReminder((prev) => !prev)} />}
            disabled={comment.trim().length === 0}
            label={t("make_it_reminder")}
          />
        </Grid>
      </Grid>

      <RecruiterReminderCreationModal
        applicationID={application.id}
        initialComment={comment}
        modalOpened={reminderModalOpened}
        closeModal={closeModal}
        onSuccess={onSuccess}
      />
    </Box>
  );
};

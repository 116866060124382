import { FC, useState } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useTranslation } from "next-i18next";

import { Button } from "@mui/material";

import { loadTranslations } from "@lib";
import { ApplicationListItem } from "@typings";

import { AdvancedFiltersModal } from "./advanced-filters-modal";

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
};

export const AdvancedFiltersAction: FC<Props> = ({ table }) => {
  const { t } = useTranslation(["applications-advanced-filters"]);
  loadTranslations("applications-advanced-filters");

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <Button
        id="advanced_filters_button"
        aria-controls={open ? "advanced_filters_action-button" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        color="deepPurple"
        sx={{
          border: 0,
        }}
      >
        {t("label")}
      </Button>
      {open && <AdvancedFiltersModal open={open} setOpen={setOpen} table={table} />}
    </>
  );
};

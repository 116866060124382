import React, { FC, useCallback } from "react";

import { useTranslation } from "next-i18next";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";

import { loadTranslations } from "@lib";
import { Application, Candidate, InterviewsConfiguration, SourcingCampaign } from "@typings";

import { ClickToCallAction } from "./click-to-call-action";
import { DeleteApplicationAction } from "./delete-application-action";
import { DownloadResumeAction } from "./download-resume-action";
import { InterviewAction } from "./interview-action";
import { RequestDocumentAction } from "./request-document-action";
import { ShareAction } from "./share-action";

type Props = {
  application: Application;
  candidate: Candidate;
  interviewConfiguration?: InterviewsConfiguration;
  sourcingCampaign: SourcingCampaign;
};

export const ActionsButton: FC<Props> = ({ application, candidate, interviewConfiguration, sourcingCampaign }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div>
        <Button
          id="actions-button"
          aria-controls={open ? "actions-button" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
          color="deepPurple"
          sx={{
            border: 0,
          }}
        >
          {t("actions.label")}
        </Button>
        <Menu
          id="actions-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          keepMounted
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <ClickToCallAction
            applicationId={application.id}
            applicantName={candidate.first_name + " " + candidate.last_name}
            applicantPhone={candidate.phone}
            render={({ onClick, disabled }) => (
              <MenuItem
                onClick={(e) => {
                  handleClose();
                  onClick(e);
                }}
                disabled={disabled}
              >
                <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                  {t("actions.call_candidate")}
                </Typography>
              </MenuItem>
            )}
          />
          {interviewConfiguration && (
            <InterviewAction
              interviewConfiguration={interviewConfiguration}
              sourcingCampaign={sourcingCampaign}
              applicationID={application.id}
              renderCreate={({ onClick, disabled }) => (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onClick();
                  }}
                  disabled={disabled}
                >
                  <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                    {t("actions.schedule_interview")}
                  </Typography>
                </MenuItem>
              )}
              renderDelete={({ onClick }) => (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    onClick();
                  }}
                >
                  <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                    {t("actions.cancel_interview")}
                  </Typography>
                </MenuItem>
              )}
            />
          )}

          <ShareAction
            application={application}
            render={({ onClick }) => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  onClick();
                }}
              >
                <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                  {t("actions.share_by_email")}
                </Typography>
              </MenuItem>
            )}
          />

          <DownloadResumeAction
            application={application}
            render={({ onClick }) => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  onClick();
                }}
              >
                <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                  {t("actions.download_profile")}
                </Typography>
              </MenuItem>
            )}
          />
          <RequestDocumentAction
            applicationID={application.id}
            render={({ onClick }) => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  onClick();
                }}
              >
                <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                  {t("actions.request_file")}
                </Typography>
              </MenuItem>
            )}
          />

          <DeleteApplicationAction
            applicationID={application.id}
            campaignID={application.campaign_id}
            render={({ onClick }) => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  onClick();
                }}
              >
                <Typography variant="body" color={(theme) => theme.palette.color.ALERT_RED[400]}>
                  {t("actions.delete_application")}
                </Typography>
              </MenuItem>
            )}
          />
        </Menu>
      </div>
    </>
  );
};

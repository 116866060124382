import { useRouter } from "next/router";

import { FC, ReactElement, useCallback } from "react";

import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { ApplicationApi } from "@api";
import { loadTranslations } from "@lib";
import { Application } from "@typings";

interface Props {
  application: Application;
  render: ({ onClick }: { onClick: () => void }) => ReactElement;
}

export const DownloadResumeAction: FC<Props> = ({ application, render }) => {
  const { t } = useTranslation(["application-details"]);
  loadTranslations("application-details");

  const { locale } = useRouter();

  const handleClick = useCallback(() => {
    ApplicationApi.getResume({ application_id: application.id, lang: locale || "fr" })
      .then(() => {
        toast.success(t("download_success"));
      })
      .catch(() => {
        toast.error(t("download_failed"));
      });
  }, [application.id, locale, t]);

  return (
    <>
      {render({
        onClick: handleClick,
      })}
    </>
  );
};

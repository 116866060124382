import { useRouter } from "next/router";

import React, { FC, useCallback, useMemo, useState } from "react";

import { MRT_TableInstance } from "material-react-table";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";

import { ArrowDropDown as ArrowDropDownIcon } from "@mui/icons-material";
import { Button, Menu, MenuItem, Typography } from "@mui/material";

import { BulkApplicationStatusUpdateModal, DeleteApplicationUpdateModal, RecomputeScoreModal } from "@components";
import { UserPermissions } from "@constants";
import { loadTranslations } from "@lib";
import { useApplicationsStore } from "@stores";
import { ApplicationListItem } from "@typings";
import { checkPermissions } from "@utils";

import { useUserPermissions } from "@hooks";

type Props = {
  table: MRT_TableInstance<ApplicationListItem>;
  campaignID: string;
};

export const GroupedActions: FC<Props> = ({ table, campaignID }) => {
  const { t } = useTranslation(["applications-grouped-actions"]);
  loadTranslations("applications-grouped-actions");

  const { data: session } = useSession();
  const userPermissions = useUserPermissions(session?.accessToken);

  const { resetRowSelection, getSelectedRowModel } = table;

  const router = useRouter();
  const { id } = router.query;

  const setCurrentApplicationID = useApplicationsStore((state) => state.setCurrentApplicationID);
  const setApplcationsID = useApplicationsStore((state) => state.setApplicationsID);
  const setCurrentCampaignID = useApplicationsStore((state) => state.setCurrentCampaignID);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const selectedApplications = getSelectedRowModel().rows.map((row) => row.original);

  const canDeleteApplication = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.ApplicationPermissions.Delete]),
    [userPermissions],
  );

  const canCreateScore = useMemo(
    () => checkPermissions(userPermissions, [UserPermissions.ScoringPermissions.Write]),
    [userPermissions],
  );

  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
  const [isDeleteApplicationModalOpen, setIsDeleteApplicationModalOpen] = useState(false);
  const [isBulkRecomputeScoreModalOpen, setIsBulkRecomputeScoreModalOpen] = useState(false);

  const handleBulkStatusChange = useCallback(() => {
    setIsBulkUpdateModalOpen(true);
    handleClose();
  }, [handleClose]);

  const handleDelete = useCallback(() => {
    if (selectedApplications.length === 0) return;
    setIsDeleteApplicationModalOpen(true);
    handleClose();
  }, [handleClose, selectedApplications.length]);

  const handleRecomputeScore = useCallback(() => {
    if (selectedApplications.length === 0) return;
    setIsBulkRecomputeScoreModalOpen(true);
    handleClose();
  }, [handleClose, selectedApplications.length]);

  const handleOpenApplications = useCallback(() => {
    if (selectedApplications.length === 0) return;
    setCurrentApplicationID(selectedApplications[0].id);
    setApplcationsID(selectedApplications.map((application) => application.id));
    setCurrentCampaignID(id as string);
    router.push({ pathname: `${router.pathname}/view`, query: { id: id as string } }, undefined, { shallow: false });
    handleClose();
  }, [handleClose, id, router, selectedApplications, setApplcationsID, setCurrentApplicationID, setCurrentCampaignID]);

  if (selectedApplications.length === 0) return null;

  return (
    <>
      <div>
        <Button
          id="bulk_actions_button"
          aria-controls={open ? "actions-button" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          endIcon={<ArrowDropDownIcon />}
          variant="contained"
          color="deepPurple"
          sx={{
            border: 0,
          }}
        >
          {t("label")}
        </Button>
        <Menu
          id="actions-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <MenuItem onClick={handleOpenApplications}>
            <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
              {t("open_applications", { count: selectedApplications.length })}
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleBulkStatusChange}>
            <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
              {t("change_status", { count: selectedApplications.length })}
            </Typography>
          </MenuItem>
          {canCreateScore && (
            <MenuItem onClick={handleRecomputeScore}>
              <Typography variant="body" color={(theme) => theme.palette.text.mainInfo}>
                {t("recompute_score", { count: selectedApplications.length })}
              </Typography>
            </MenuItem>
          )}
          {canDeleteApplication && (
            <MenuItem onClick={handleDelete}>
              <Typography variant="body" color={(theme) => theme.palette.color.ALERT_RED[400]}>
                {t("delete_applications", { count: selectedApplications.length })}
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </div>

      {isBulkUpdateModalOpen && (
        <BulkApplicationStatusUpdateModal
          applications={selectedApplications}
          campaignID={campaignID}
          open={isBulkUpdateModalOpen}
          setOpen={setIsBulkUpdateModalOpen}
          onSuccess={() => resetRowSelection()}
        />
      )}

      {isDeleteApplicationModalOpen && (
        <DeleteApplicationUpdateModal
          applicationIDs={selectedApplications.map((application) => application.id)}
          open={isDeleteApplicationModalOpen}
          campaignID={id as string}
          setOpen={setIsDeleteApplicationModalOpen}
          onSuccess={() => resetRowSelection()}
        />
      )}

      {isBulkRecomputeScoreModalOpen && (
        <RecomputeScoreModal
          applicationIDs={selectedApplications.map((application) => application.id)}
          open={isBulkRecomputeScoreModalOpen}
          setOpen={setIsBulkRecomputeScoreModalOpen}
          onSuccess={() => resetRowSelection()}
        />
      )}
    </>
  );
};
